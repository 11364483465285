/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import providerEntityService from 'service/providerEntity';
import accessesService from 'service/legalEntityAccess';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import countryService from 'service/country';
import regionService from 'service/region';
import areaService from 'service/district';
import activityService from 'service/activity';

interface AddProviderProps {
  setOpenProvider: (show: boolean) => void;
  selectedProviderId?: any;
  refetch?: any;
  setSelectedProviderId?: any;
}

const AddProvider = ({
  setOpenProvider,
  selectedProviderId,
  refetch,
  setSelectedProviderId
}: AddProviderProps) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const system_level = useSelector(
    (state: any) => state?.auth?.user?.role?.system_level
  );
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const [loading, setLoading] = useState(false);
  const [legalEntityAccessOption, setLegalEntityAccessOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [activityKindOption, setActivityKindOption] = useState([]);
  const [typeOption, setTypeOption] = useState<
    { value: string; label: string }[]
  >([]);

  //GET Access to options
  useQuery(['GET_LEGAL_ENTITY_ACCESS'], async () => {
    await accessesService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_accesses?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setLegalEntityAccessOption(options);
      });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.countries?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setCountryOption(options);
      });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.regions?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setRegionOption(options);
      });
  });

  //GET District to options
  useQuery(['GET_DITRICT'], async () => {
    await areaService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.districts?.map((option: any) => ({
        value: option?.id,
        label: option?.name?.[i18n?.language]
      }));
      setDistrictOption(options);
    });
  });

  //GET Activit Kind to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setActivityKindOption(options);
      });
  });

  const fetchData = () => {
    if (selectedProviderId === '') return setLoading(false);

    providerEntityService
      .getById(selectedProviderId)
      .then((res: any) => {
        const computed = {
          interaction_type: res?.legal_entity_access?.id,
          individual: res?.name,
          default_currency: res?.currency?.id,
          country: res?.country.id,
          region: res?.region?.id,
          area: res?.district?.id,
          phone: res?.phone_number,
          address: res?.address,
          output_activite: res?.activity_kind?.id,
          type_physical_name: res?.legal_entity_type,
          legal_entity_status_id: res?.legal_entity_status_id
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedProviderId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      legal_entity_access_id: data?.interaction_type,
      name: data?.individual,
      currency_id: data?.default_currency,
      country_id: data?.country,
      region_id: data?.region,
      district_id: data?.area,
      phone_number: data?.phone,
      address: data?.address,
      activity_kind_id: data?.output_activite,
      legal_entity_type: data?.type_physical_name,
      cashbox_id: cashboxId
    };

    providerEntityService
      .create(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('supplier')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenProvider(false);
        setLoading(false);
        setSelectedProviderId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      legal_entity_access_id: data?.interaction_type,
      name: data?.individual,
      currency_id: data?.default_currency,
      country_id: data?.country,
      region_id: data?.region,
      district_id: data?.area,
      phone_number: data?.phone,
      address: data?.address,
      activity_kind_id: data?.output_activite,
      legal_entity_type: data?.type_physical_name,
      legal_entity_status_id: data?.legal_entity_status_id,
      is_custom: true,
      id: selectedProviderId
    };
    providerEntityService.update(selectedProviderId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenProvider(false);
      setSelectedProviderId('');
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedProviderId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedProviderId]);

  useEffect(() => {
    let updatedTypeOption: { value: string; label: string }[] = [];

    if (system_level === 'ROOT') {
      updatedTypeOption = [
        {
          value: 'HOLDING',
          label: t('holding')
        },
        {
          value: 'BRAND',
          label: t('brand')
        },
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'HOLDING') {
      updatedTypeOption = [
        {
          value: 'BRAND',
          label: t('brand')
        },
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'BRAND') {
      updatedTypeOption = [
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'DEALER') {
      updatedTypeOption = [
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'STORE') {
      updatedTypeOption = [
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    }

    setTypeOption([...updatedTypeOption]);
  }, [system_level]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="type_physical_name"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    ref={inputRef}
                    isInvalid={!!errors.type_physical_name}
                  >
                    {!field.value && <option value="" disabled hidden></option>}
                    {typeOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('type_individual')}
                  </label>
                  {errors?.type_physical_name && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      {t('required_field')}
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="interaction_type"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.interaction_type}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityAccessOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('interaction_type')}
                    </label>
                    {errors?.interaction_type && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="individual"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('name_individual')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.individual}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('name_individual')}
                  </label>
                  {errors.individual && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="default_currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('default_currency')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.country}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('country')}</label>
                    {errors?.country && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {regionOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('region')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {districtOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('area')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('phone')}
                type="tel"
                placeholder={t('phone_number')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('phone_number')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('address')}
                type="tel"
                placeholder="Адрес"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('address')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="output_activite"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.output_activite}
                    >
                      <option className="d-none" value=""></option>
                      {activityKindOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('enter_activities')}
                    </label>
                    {errors?.output_activite && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default AddProvider;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import 'flatpickr/dist/flatpickr.css';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import PhoneNumberInput from 'components/forms/PhoneNumberInput';
import useCountryUnicode from 'hooks/useCountryUnicode';
import regionService from 'service/region';
import countryService from 'service/country';
import areaService from 'service/district';
import purchaserService from 'service/purchaser';

interface CreatePurchaserProps {
  setOpenPurchaser: (show: boolean) => void;
  refetch?: any;
}

const CreatePurchaser = ({
  setOpenPurchaser,
  refetch
}: CreatePurchaserProps) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const genderOption = [
    {
      value: 'MALE',
      label: t('male')
    },
    {
      value: 'FEMALE',
      label: t('female')
    }
  ];

  const [loading, setLoading] = useState(false);
  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState<any>([]);
  const [countryOption, setCountryOption] = useState<any>([]);

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const unicode = useCountryUnicode();

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.countries?.map((country: any) => ({
          value: country.id,
          label: country.name?.[i18n?.language]
        }));
        setCountryOption(options);
      });
  });

  //GET Area to options
  useQuery(['GET_AREA'], async () => {
    await areaService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.districts?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setAreaOption(options);
      });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.regions?.map((region: any) => ({
          value: region.id,
          label: region.name?.[i18n?.language]
        }));
        setRegionOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    const createData = {
      first_name: data?.first_name,
      gender: data?.gender,
      last_name: data?.second_name,
      phone_number: data?.phone,
      surname: data?.client_patronymic,
      country_id: data?.country,
      district_id: data?.area,
      region_id: data?.region
    };

    purchaserService
      .create(createData)
      .then((res: any) => {
        if (res) {
          setOpenPurchaser(false);
          setLoading(false);
          dispatch(
            showAlert({
              title: `${t('buyer')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
        }
      })
      .catch((error: any) => {
        setLoading(false);

        if (error.status === 409) {
          console.log('error409', error);
        }
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="form-icon-container">
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <PhoneNumberInput
                    field={field}
                    ref={inputRef}
                    unicode={unicode}
                    isInvalid={!!errors.phone}
                  />
                )}
              />
              {errors?.phone && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('second_name')}
                type="text"
                placeholder={t('second_name')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('second_name')}</label>
              {errors.second_name && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t('name')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.first_name}
                  />
                  <label htmlFor="floatingInputCustom">{t('name')}</label>
                  {errors?.first_name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('client_patronymic')}
                type="text"
                placeholder={t('surname')}
                onFocus={e => e.target.select()}
                autoComplete="new-patronymic"
              />
              <label htmlFor="floatingInputCustom">{t('surname')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('country')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {regionOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('region')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {areaOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      )) ?? []}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('area')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.gender}>
                      <option className="d-none" value=""></option>
                      {genderOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('gender')}</label>
                  </Form.Floating>
                )}
              />
              {errors?.gender && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreatePurchaser;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Filter from './filter/Filter';
import NotInLists from './NotInLists';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import UpdateQuantity from './UpdateQuantity';
import inventoryItemService from 'service/inventoryItem';
import inventoryService from 'service/inventory';
import { showAlert } from 'store/alert/alert.thunk';
import SupperModal from 'components/modals/SupperModal';
import usePageSearchParams from 'utils/usePageSearchParams';
import './styles.scss';

export const createInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'inventory',
    url: '/inventory/create-inventory'
  },
  {
    label: 'create_inventory',
    url: '/inventory/create-inventory'
  },
  {
    label: 'new',
    active: true
  }
];

export type CreateInventoryData = {
  product_name: string;
  barcode: string;
  units: string;
  currency: string;
  cost: string;
  accounts_number: string;
  quantity_fact: string;
  shortage: string;
  surplus: string;
  deficiency_amount: string;
  surplus_amount: string;
  status: string;
  guid: string;
};

const CreateInventory = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const inventoryId = pathname.split('/').pop();
  const [searchParams] = usePageSearchParams();
  const warehouuseId = searchParams?.get('warehouse_id');

  const [openNotInList, setOpenNotInList] = useState(false);
  const [openAmount, setOpenAmount] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState({
    id: '',
    quantity: ''
  });
  const [tableData, setTableData] = useState<any>({});
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | null>(
    null
  );
  const [openFinishSupperModal, setOpenFinishSupperModal] = useState(false);
  const [confirmToFinish, setConfirmToFinish] = useState(false);

  const [selectedInventoryBarcode, setSelectedInventoryBarcode] = useState<
    string | null
  >(null);

  const dispatch: Dispatch<any> = useDispatch();

  //Get Inventory Item
  const { data, refetch: refetchInventoryItem } = useQuery(
    ['GET_INVENTORY_ITEM', inventoryId],
    () => {
      if (inventoryId !== '') {
        const res = inventoryItemService
          .getInventoryItemById(inventoryId, { offset: 0, limit: 10 })
          .then((res: any) => res?.inventory_items);

        return res;
      }
    },
    {
      enabled: !!inventoryId
    }
  );

  const inventoryItemData: CreateInventoryData[] = useMemo(() => {
    const safeValue = (value: any) =>
      value === undefined || value === null ? 0 : value;
    const res =
      data?.map((items: any) => {
        if (items && items?.product?.barcode) {
          const tableDataUpdate = {
            [items?.product?.barcode]: {
              quantity_fact: items?.quantity_actual ?? 0,
              guid: items?.id
            }
          };

          setTableData((prevTableData: any) => ({
            ...prevTableData,
            ...tableDataUpdate
          }));
        }
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          cost: safeValue(items?.price_in),
          accounts_number: safeValue(items?.quantity),
          quantity_fact: safeValue(items?.quantity_actual),
          shortage: Math.max(
            safeValue(items?.quantity) - safeValue(items?.quantity_actual),
            0
          ),
          surplus: Math.max(
            safeValue(items?.quantity_actual) - safeValue(items?.quantity),
            0
          ),
          deficiency_amount:
            Math.max(
              safeValue(items?.quantity) - safeValue(items?.quantity_actual),
              0
            ) * safeValue(items?.price_in),
          surplus_amount:
            Math.max(
              safeValue(items?.quantity_actual) - safeValue(items?.quantity),
              0
            ) * safeValue(items?.price_in),
          invoice_quantity: safeValue(items?.invoice_quantity),
          return_client_quantity: safeValue(items?.return_client_quantity),
          return_provider_quantity: safeValue(items?.return_provider_quantity),
          sale_quantity: safeValue(items?.sale_quantity),
          order_quantity: safeValue(items?.order_quantity),
          moving_quantity: safeValue(items?.moving_quantity),
          defective_minus_quantity: safeValue(items?.defective_minus_quantity),
          defective_plus_quantity: safeValue(items?.defective_plus_quantity),
          /* prettier-ignore */
          current_balance:
            safeValue(items?.quantity_actual) +
            safeValue(items?.invoice_quantity) +
            safeValue(items?.return_client_quantity) +
            safeValue(items?.return_provider_quantity) +
            safeValue(items?.sale_quantity) +
            safeValue(items?.order_quantity) +
            safeValue(items?.moving_quantity) +
            safeValue(items?.defective_minus_quantity) +
            safeValue(items?.defective_plus_quantity),
          status: items?.status?.name?.ru,
          guid: items?.id,
          product_id: items?.product_id
        };
      }) ?? [];

    return res;
  }, [data, inventoryId, setTableData, t, i18n?.language]);

  const handleDelete = (id: any, barcode: any) => {
    setSelectedInventoryId(id);
    setOpenSupperModal(true);
    setSelectedInventoryBarcode(barcode);
  };

  const createInventoryDataTableColumns: ColumnDef<CreateInventoryData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() =>
              handleDelete(
                rowData?.row?.original?.guid,
                rowData?.row?.original?.barcode
              )
            }
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accounts_number',
      header: t('quantity_accounting'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: t('number_facts'),
      cell: rowData => {
        const amount = String(rowData?.getValue());
        return (
          <div>
            <Button
              variant="primary"
              onClick={() => {
                setOpenAmount(true);
                setSelectedQuantity({
                  id: rowData?.row?.original?.guid,
                  quantity: rowData?.row?.original?.quantity_fact
                });
              }}
            >
              {amount}
            </Button>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shortage',
      header: t('shortage'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus',
      header: t('excess'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'deficiency_amount',
      header: t('amount_deficiency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus_amount',
      header: t('amount_surplus'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'invoice_quantity',
      header: t('purchase'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'return_client_quantity',
      header: t('customer_return'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'return_provider_quantity',
      header: t('supplier_return'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sale_quantity',
      header: t('sale'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'order_quantity',
      header: t('order'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'moving_quantity',
      header: t('movements'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'defective_minus_quantity',
      header: t('marriage_goods_minus'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'defective_plus_quantity',
      header: t('marriage_goods_plus'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: t('current_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryItemData,
    columns: createInventoryDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleFinish = () => {
    if (inventoryItemData?.length === 0) {
      dispatch(
        showAlert({
          title: t('product_not_added')
        })
      );
      setConfirmToFinish(false);
    }
    /* prettier-ignore */
    if (inventoryId && inventoryItemData?.length > 0) {
      inventoryService.getInventoryById(inventoryId).then((res: any) => {
        if (res) {
          const today = new Date();
          /* prettier-ignore */
          inventoryService
              .updateInventory(inventoryId, {
                ...res,
                status_id: 'af0fe0e3-3357-4b29-a33f-138c89cb75db',
                date_close: today.getTime()
              })
              .then((response: any) => {
                if (response) {
                  dispatch(
                    showAlert({
                      title: `${t("inventory")} ${t("successfully_added")}`,
                      type: 'success'
                    })
                  );
                  navigate('/inventory/create-inventory');
                }
              });
        }
      });
    }
  };

  const sumOfAllSurplus = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.surplus_amount === undefined ? 0 : +item.surplus_amount;
    return acc + allCost;
  }, 0);

  const sumOfAllDeficiency = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.deficiency_amount === undefined ? 0 : +item.deficiency_amount;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    if (confirmToDelete && selectedInventoryId && selectedInventoryBarcode) {
      inventoryItemService
        .deleteInventoryItem(selectedInventoryId)
        .finally(() => {
          refetchInventoryItem();
          setConfirmToDelete(false);
          setSelectedInventoryId(null);
          setOpenSupperModal(false);
        });

      setTableData((prevTableData: any) => {
        const updatedTableData = { ...prevTableData };
        delete updatedTableData[selectedInventoryBarcode];
        return updatedTableData;
      });
    }
  }, [
    confirmToDelete,
    selectedInventoryId,
    refetchInventoryItem,
    selectedInventoryBarcode
  ]);

  useEffect(() => {
    if (confirmToFinish) {
      handleFinish();
    }
  }, [confirmToFinish]);

  return (
    <div>
      <PageBreadcrumb items={createInventoryBreadcrumbItems} />
      <div>
        <Filter
          refetchInventoryItem={refetchInventoryItem}
          tableData={tableData}
          setTableData={setTableData}
        />

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <div className="footer-inv-create">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount_shortages')}: </p>
            <span>{sumOfAllDeficiency ?? 0}</span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount_surplus')}: </p>
            <span>{sumOfAllSurplus ?? 0}</span>
          </div>

          <div className="main-btn-group">
            <Button variant="primary" onClick={() => setOpenNotInList(true)}>
              {t('not_added_list')}
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setOpenFinishSupperModal(true)}
            >
              {t('complete_invertization')}
            </Button>
          </div>
        </div>
      </div>
      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title={t('added_products')}
        bodyText={t('you_want_delete')}
      />
      <SupperModal
        show={openFinishSupperModal}
        setShow={setOpenFinishSupperModal}
        setConfirm={setConfirmToFinish}
        title={t('complete_invertization')}
        bodyText={t('want_complete_inventory')}
      />
      <Modal
        show={openNotInList}
        onHide={() => setOpenNotInList(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('uninventorized_goods')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotInLists
            inventoryItemData={inventoryItemData}
            inventoryId={inventoryId}
            warehouuseId={warehouuseId}
          />
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={openAmount}
        onHide={() => setOpenAmount(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('quantity')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UpdateQuantity
            setOpenAmount={setOpenAmount}
            selectedQuantity={selectedQuantity}
            refetchInventoryItem={refetchInventoryItem}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CreateInventory;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const transactionService = {
  getList: (params: any) => request.get('/transaction', { params }),
  getTransactionById: (id: any) => request.get(`/transaction/${id}`),
  createTransaction: (data: any) => request.post('/transaction', data),
  deleteTransaction: (id: any) => request.delete(`/transaction/${id}`),
  updateTransaction: (id: any, data: any) =>
    request.put(`/transaction/${id}`, data)
};

export default transactionService;

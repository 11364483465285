/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import Button from 'components/base/Button';
import warehouseService from 'service/warehouse';
import usersService from 'service/users';
import { showAlert } from 'store/alert/alert.thunk';
import inventoryService from 'service/inventory';
import './styles.scss';

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => field.onChange(e.target.checked)}
    />
  );
};

const OpenFolder = () => {
  const { t, i18n } = useTranslation();

  const schema = yup.object().shape({
    stock: yup.string().required(t('required_field')),
    number: yup.string().required(t('required_field')),
    date_maturity: yup?.date().required(t('required_field')),
    responsible: yup?.string().required(t('required_field')),
    smart_mode: yup?.boolean()
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [usersOption, setUsersOption] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET Warehouse to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Users to options
  useQuery(['GET_USERS'], async () => {
    await usersService
      .getList({ offset: 0, limit: 100, is_charger_inventory: true })
      .then((res: any) => {
        const options = res?.data?.users?.map((option: any) => ({
          value: option.id,
          label: `${option.first_name}  ${option?.last_name}`
        }));
        setUsersOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);
    const createdData = {
      warehouse_id: data?.stock,
      inventory_number: data?.number,
      inventory_date: Date.parse(data?.date_maturity),
      employee_id: data?.responsible,
      smart_mode: data?.smart_mode
    };

    inventoryService
      ?.createInventory(createdData)
      .then((res: any) => {
        setLoading(false);

        if (res) {
          dispatch(
            showAlert({
              title: `${t('inventory')} ${t('successfully_added')}`,
              type: 'success'
            })
          );

          navigate(`/inventory/main/${res?.id}?warehouse_id=${data?.stock}`);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (warehouseOption.length === 1) {
      setValue('stock', warehouseOption[0].value);
    }
  }, [warehouseOption, setValue]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div className="d-flex flex-column gap-2">
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="stock"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.stock}>
                      <option className="d-none" value=""></option>
                      {warehouseOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('warehouse')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.stock?.message && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  {errors?.stock?.message}
                </span>
              )}
            </div>
          </Form.Group>
          <Form.Floating>
            <Form.Control
              {...register('number')}
              type="number"
              placeholder={t('inventory_number')}
              isInvalid={!!errors.number}
              onFocus={e => e.target.select()}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">{t('inventory_number')}</label>
            {errors.number && (
              <Form.Control.Feedback type="invalid">
                {errors.number.message}
              </Form.Control.Feedback>
            )}
          </Form.Floating>
          <Form.Group>
            <Controller
              name="date_maturity"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Flatpickr
                    className={classNames('form-control', {
                      error: errors.date_maturity?.message
                    })}
                    options={{
                      nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                      prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                      locale: getFlatpickrLocale(),
                      monthSelectorType: 'static',
                      onDayCreate: (...[, , , dayElem]) => {
                        if (
                          dayElem.dateObj.getDay() === 5 ||
                          dayElem.dateObj.getDay() === 6
                        ) {
                          dayElem.className += ' weekend-days';
                        }
                      },
                      dateFormat: 'M j, Y',
                      disableMobile: true
                    }}
                    placeholder={t('maturity_date')}
                    {...field}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('maturity_date')}
                  </label>
                </Form.Floating>
              )}
            />
            {errors?.date_maturity?.message && (
              <span
                style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
              >
                {t('required_field')}
              </span>
            )}
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="responsible"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.responsible}>
                      <option className="d-none" value=""></option>
                      {usersOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      {t('financially_responsible_person')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.responsible?.message && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  {errors?.responsible?.message}
                </span>
              )}
            </div>
          </Form.Group>

          <Form.Group>
            <Controller
              name="smart_mode"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <SwitchControl field={field} label={t('smart_mode')} />
              )}
            />
          </Form.Group>
        </div>

        <div style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="primary"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('create')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default OpenFolder;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Form, Offcanvas, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import scalesService from 'service/scales';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import UpdateScales from './UpdateScales';
import AddProductScalesTable from './AddProductScalesTable';

export type scalesType = {
  name: string;
  barcode: string;
  measure_unit: string;
  scale_code: string;
  date_create: string;
  price: string;
  maturity_date: string;
  guid: string;
};

export const scalesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'scales_and_weighing_products',
    active: true
  }
];

export const txtFileOption = [
  {
    label: 'export_scales_barcode',
    value: 'Экспорт на весы ШТРИХ-М'
  },
  {
    label: 'export_to_excel',
    value: 'Экспорт на EXCEL'
  }
];

const Scales = () => {
  const { t, i18n } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [selectedScaleId, setSelectedScaleId] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [createModal, setCreateModal] = useState(false);

  //Get Product Search
  const { data, refetch } = useQuery(
    ['GET_SCALES', searchInputValue, getBarcode],
    async () => {
      const res = await scalesService
        .getList({
          limit: 10,
          offset: 0,
          name: searchInputValue,
          barcode: getBarcode
        })
        .then((res: any) => {
          return res?.products;
        });
      return res;
    },
    {
      enabled: !!getBarcode || !searchInputValue || searchInputValue?.length > 2
    }
  );

  const scalesData: scalesType[] = useMemo(() => {
    const formatDate = (dateStr: any) => {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    };

    const todayFormatted = dayjs().format('DD.MM.YYYY');

    return (
      data?.map((items: any) => {
        return {
          barcode: items?.barcode,
          scale_code: items?.scale_code,
          name: items?.name,
          measure_unit:
            items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          price: items?.price
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price)
                .replace(/,/g, ' ')
            : 0,
          date_create: items?.date_created
            ? formatDate(items?.date_created)
            : '',
          maturity_date: items?.maturity_date
            ? formatDate(items?.maturity_date)
            : todayFormatted,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: string) => {
    if (id) {
      scalesService.updateScales(id, { scale_code: 0 }).finally(() => {
        refetch();
      });
    }
  };

  const scalesTableColumns: ColumnDef<scalesType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex gap-2">
          <Button
            variant="hover"
            onClick={() => {
              setOpenModal(true);
              setSelectedScaleId(rowData?.row?.original);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            variant="hover"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'scale_code',
      header: t('weight_barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'measure_unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: t('selling_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: scalesData,
    columns: scalesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const generateTxtFile = () => {
    let fileContent = '';

    scalesData.forEach((item, index) => {
      /* prettier-ignore */
      const row = `${index + 1};${item?.name};;${String(item?.price).split(' ').join('')};0;0;0;${item?.scale_code};0;0;;${item?.date_create}${item?.measure_unit === 'Dona' || item?.measure_unit === 'Штука' ? ';1;0;0;0;' : ';0;0;0;0;'}${item?.maturity_date}\n`;
      fileContent += row;
    });

    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'ШТРИХ-М.txt');
  };

  const generateExcelFile = () => {
    /* prettier-ignore */
    const worksheetData = scalesData.map((item, index) => ({
      '№': index + 1,
      'Наименование товара': item?.name,
      'Штрих-код весовой': item?.scale_code,
      'Цена продажи': String(item?.price).split(' ').join('')
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Scales Data');

    XLSX.writeFile(workbook, 'EXCEL.xlsx');
  };

  useEffect(() => {
    if (txtFileOption) {
      setSelectedType(txtFileOption[0].value);
    }
  }, []);

  return (
    <div>
      <PageBreadcrumb items={scalesBreadcrumbItems} />

      <h2 className="mb-5">{t('scales_and_weighing_products')}</h2>

      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder={t('enter_product')}
                onFocus={e => e.target.select()}
                onChange={e => setSearchInputValue(e.target.value)}
                className="form-icon-input"
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className="form-icon-label text-700"
              >
                {t('enter_product')}
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faSearch}
              className="text-900 fs-9 form-icon"
            />
          </div>

          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder={t('enter_barcode')}
                onFocus={e => e.target.select()}
                onChange={e => setGetBarcode(e.target.value)}
                className="form-icon-input"
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className="form-icon-label text-700"
              >
                {t('enter_barcode')}
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faBarcode}
              className="text-900 fs-9 form-icon"
            />
          </div>
        </div>
        <div className="d-flex gap-2">
          <Form.Floating>
            <Form.Select
              value={selectedType}
              onChange={e => {
                setSelectedType(e?.target?.value);
              }}
            >
              <option className="d-none" value=""></option>
              {txtFileOption?.map((option: any) => (
                <option
                  key={option.value}
                  value={option.value}
                  className="option"
                >
                  {t(option.label)}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">
              {t('select_type_scales_export')}
            </label>
          </Form.Floating>

          <Button
            disabled={selectedType === ''}
            variant="outline-primary"
            onClick={
              selectedType === 'Экспорт на EXCEL'
                ? generateExcelFile
                : generateTxtFile
            }
          >
            <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
            {t('export')}
          </Button>

          <Button variant="primary" onClick={() => setCreateModal(true)}>
            <FeatherIcon icon="plus" className="me-2" size={20} />
            {t('add')}
          </Button>
        </div>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setSelectedScaleId({ guid: '' });
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            {t('changed')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UpdateScales
            selectedScaleId={selectedScaleId}
            setSelectedScaleId={setSelectedScaleId}
            refetch={refetch}
            setOpenModal={setOpenModal}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={createModal}
        onHide={() => {
          setCreateModal(false);
          refetch();
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('add_product')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProductScalesTable />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Scales;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import ProductCreateFilter from './ProductCreateFilter';
import reportInvoiceService from 'service/reportInvoice';

interface ProductFilterProps {
  setGeneratedTableData?: any;
  savedValues?: any;
  setSavedValues?: any;
  setGetName?: any;
  setGetBarcode?: any;
  getName?: any;
  getBarcode?: any;
  setGetFilterData?: any;
  setCurrencyName?: any;
  getFilterData?: any;
}

const ProductFilter = ({
  setGeneratedTableData,
  savedValues,
  setSavedValues,
  setGetName,
  setGetBarcode,
  getName,
  getBarcode,
  setGetFilterData,
  setCurrencyName,
  getFilterData
}: ProductFilterProps) => {
  const url = process.env.REACT_APP_CDN_URL;
  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    const customFilename: string = 'Продажа по товарам.xlsx';

    // Prepare request payload
    const requestData = {
      name: getName,
      date_from:
        Object.keys(getFilterData).length === 0
          ? todayStart
          : getFilterData?.date_from,
      date_to:
        Object.keys(getFilterData).length === 0
          ? todayEnd + 86400000
          : getFilterData?.date_to,
      barcode: getBarcode,
      supplier_id: getFilterData?.supplier_id
        ? getFilterData?.supplier_id
        : undefined,
      currency_id: getFilterData?.currency_id
        ? getFilterData?.currency_id
        : undefined,
      warehouse_id: getFilterData?.warehouse_id
        ? getFilterData?.warehouse_id
        : undefined,
      measure_unit_id: getFilterData?.measure_unit_id
        ? getFilterData?.measure_unit_id
        : undefined,
      is_group: getFilterData?.is_group ? getFilterData?.is_group : undefined,
      employee_id: getFilterData?.employee_id
        ? getFilterData?.employee_id
        : undefined,
      type: getFilterData?.type ? getFilterData?.type : undefined
    };

    // Fetch report and trigger download
    reportInvoiceService
      ?.getReportSaleListExcel(requestData)
      .then((res: any) => {
        setLoading(false);
        if (res?.filename !== '') {
          const resUrl = `${url}${res?.filename}`;
          const link = document.createElement('a');
          link.href = resUrl;
          link.download = customFilename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error==>', error);
      });
  };

  return (
    <>
      <Form className="d-flex gap-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setGetName(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('scan_barcode')}
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('scan_barcode')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Button
          style={{ height: '48px' }}
          variant="outline-primary"
          onClick={() => setOpenModal(true)}
        >
          <FeatherIcon icon="filter" className="me-2" size={20} />
          {t('filter')}
        </Button>

        <Button
          style={{ height: '48px' }}
          variant="outline-primary"
          onClick={handleExport}
          loading={loading}
          loadingPosition="start"
        >
          <FeatherIcon icon="upload-cloud" className="me-2" size={20} />
          {t('export')}
        </Button>

        <Offcanvas
          show={openModal}
          onHide={() => {
            setOpenModal(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              {t('filter')}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ProductCreateFilter
              setGeneratedTableData={setGeneratedTableData}
              savedValues={savedValues}
              setSavedValues={setSavedValues}
              setOpenModal={setOpenModal}
              getName={getName}
              getBarcode={getBarcode}
              setGetFilterData={setGetFilterData}
              setCurrencyName={setCurrencyName}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </>
  );
};

export default ProductFilter;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import ClientsTable from './ClientsTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { clientsBreadcrumbItems } from 'data/clients';
import CreateClients from './CreateClients';
import usersService from 'service/users';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type ClientsType = {
  phone: string;
  legal_name: string;
  second_name: string;
  first_name: string;
  patronymic: string;
  country: string;
  region: string;
  area: string;
  gender: string;
  date_birth: string;
  status: string;
  guid: string;
  legal_entity_type: string;
};

const Clients = () => {
  const { t, i18n } = useTranslation();

  const [openClient, setOpenClient] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');

  console.log('post======>');

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get USERS
  const { data, refetch } = useQuery(['GET_USERS'], () => {
    const res = usersService
      .getList({ offset: 0, limit: 1000 })
      .then((res: any) => res?.data?.users);
    return res;
  });

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const clientsData: ClientsType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          phone: items?.phone_number,
          legal_entity: items?.legal_entity?.name,
          guid: items?.id,
          second_name: items?.first_name,
          first_name: items?.last_name,
          patronymic: items?.surname,
          /* prettier-ignore */
          date_birth: items?.birth_date ? formatDateToTimeZone(items?.birth_date, timeZone): "",
          gender: items?.gender === 'MALE' ? t('male') : t('female'),
          role: items?.role?.name,
          country_id: items?.country?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          status: items?.user_status?.name,
          is_charger_inventory:
            items?.is_charger_inventory === true ? t('yes') : t('no'),
          is_charger_return:
            items?.is_charger_return === true ? t('yes') : t('no'),
          is_blocked: items?.is_blocked === true ? t('yes') : t('no')
        };
      }) ?? []
    );
  }, [data, dataSettings, t, i18n?.language]);

  const clientsTableColumns: ColumnDef<ClientsType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div>
            <Button
              variant="hover"
              onClick={() => {
                setSelectedClientId(rowData?.row?.original?.guid);
                setOpenClient(true);
              }}
            >
              <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'legal_entity',
      header: t('legal_entity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'role',
      header: t('access_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'second_name',
      header: t('second_name'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'first_name',
      header: t('name'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'patronymic',
      header: t('surname'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },

    {
      accessorKey: 'country_id',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'gender',
      header: t('gender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_birth',
      header: t('date_birth'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'is_charger_inventory',
      header: t('inventory_manager'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'is_charger_return',
      header: t('responsible_for_returns'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'is_blocked',
      header: t('blocked'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: clientsData,
    columns: clientsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={clientsBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('users')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenClient(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>

                {/* <Button variant="primary">Применить</Button> */}
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <ClientsTable />
          </div>

          <Offcanvas
            show={openClient}
            onHide={() => {
              setOpenClient(false);
              setSelectedClientId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateClients
                setOpenClient={setOpenClient}
                selectedClientId={selectedClientId}
                refetch={refetch}
                setSelectedClientId={setSelectedClientId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Clients;

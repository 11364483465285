/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DocPageHeader from 'components/docs/DocPageHeader';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import currencyService from 'service/currency';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';

interface CreateCurrencyExchangeRateProps {
  setOpenConversion: (show: boolean) => void;
  refetch?: any;
}

const CreateCurrencyExchangeRate = ({
  setOpenConversion,
  refetch
}: CreateCurrencyExchangeRateProps) => {
  const { t, i18n } = useTranslation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({});

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [mainCurrencyOptions, setMainCurrencyOptions] = useState<any[]>([]);
  const [unitsOptions, setUnitsOptions] = useState<any[]>([]);
  const [originalUnitsOptions, setOriginalUnitsOptions] = useState<any>([]);
  const [selectedMainCurrency, setSelectedMainCurrency] = useState<any>(null);

  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setMainCurrencyOptions(options);
        setUnitsOptions(options);
        setOriginalUnitsOptions(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createData = {
      income_amount: 1,
      outcome_amount: +data?.convertible || 0,
      income_currency_id: data?.main_currency,
      outcome_currency_id: data?.units
    };

    legalEntityCurrencyRateService
      ?.createConversionRate(createData)
      ?.then((res: any) => {
        if (res) {
          /* prettier-ignore */
          dispatch(
            showAlert({
             title: `${t('currency_exchange_rate')} ${t('successfully_added')}`,
              type: 'success'
            })
          );

          refetch();
          setOpenConversion(false);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (selectedMainCurrency && inputRef.current) {
      inputRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current, selectedMainCurrency]);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
        <div className="d-flex gap-2 justify-content-between mb-3">
          <Form.Group className="w-100">
            <Controller
              name="base"
              control={control}
              render={({ field }) => (
                <Form.Floating className="w-100">
                  <Form.Control
                    {...field}
                    disabled
                    defaultValue="1"
                    type="text"
                    placeholder="Base currency"
                    onFocus={e => e.target.select()}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('unit_base_currency')}
                  </label>
                </Form.Floating>
              )}
            />
          </Form.Group>

          <Form.Group className="w-100">
            <div className="react-select-container">
              <Controller
                name="main_currency"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      ref={inputRef}
                      onChange={(e: any) => {
                        const selectedOption = mainCurrencyOptions.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedOption) {
                          setSelectedMainCurrency(selectedOption);

                          const updatedUnitsOptions =
                            originalUnitsOptions.filter(
                              (option: any) =>
                                option.value !== selectedOption?.value
                            );
                          console.log(
                            'updatedUnitsOptions',
                            updatedUnitsOptions
                          );
                          if (updatedUnitsOptions.length === 1) {
                            setUnitsOptions(updatedUnitsOptions);
                            setValue('units', updatedUnitsOptions[0].value); // Automatically select the first available option if length is 2
                          } else {
                            setUnitsOptions(updatedUnitsOptions);
                            setValue('units', ''); // Clear the units select if length is not 2
                          }
                        }

                        field.onChange(e);
                      }}
                      isInvalid={!!errors.main_currency}
                    >
                      <option className="d-none" value=""></option>
                      {mainCurrencyOptions?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('main_currency')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.main_currency && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Form.Group>

          <Form.Group>
            <DocPageHeader
              className="d-flex justify-content-center align-items-center"
              title="="
            />
          </Form.Group>

          <Form.Group className="w-100">
            <div className="react-select-container">
              <Controller
                name="units"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = unitsOptions.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedMainCurrency) {
                          setUnitsOptions(originalUnitsOptions);
                          setSelectedMainCurrency(null);
                        } else {
                          setUnitsOptions(
                            unitsOptions.filter(
                              (option: any) =>
                                option.value !== selectedOption?.value
                            )
                          );
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.units}
                    >
                      <option className="d-none" value=""></option>
                      {unitsOptions?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      {t('convertible_currency_unit')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.units && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {t('required_field')}
                </span>
              )}
            </div>
          </Form.Group>

          <Form.Group className="w-100">
            <Controller
              name="convertible"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating className="w-100">
                  <Form.Control
                    {...field}
                    type="text"
                    ref={selectedMainCurrency && inputRef}
                    placeholder="Base convertible"
                    onFocus={e => e.target.select()}
                    autoComplete="off"
                    isInvalid={!!errors?.convertible}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('convertible_currency')}
                  </label>

                  {errors?.convertible && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      {t('required_field')}
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </Form.Group>
        </div>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateCurrencyExchangeRate;

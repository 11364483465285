/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const reportInvoiceService = {
  getList: (params: any) => request.get('/report/invoice', { params }),
  getListExcel: (params: any) =>
    request.get('/report/invoice/excel', { params }),
  getReportProduct: (params: any) => request.get('/product', { params }),
  getReportProductList: (params: any) =>
    request.get('/report/product', { params }),
  getReportProductListExcel: (params: any) =>
    request.get('/report/product/excel', { params }),
  getReportSaleList: (params: any) => request.get('/report/sale', { params }),
  getReportSaleListExcel: (params: any) =>
    request.get('/report/sale/excel', { params }),
  getReportRemainingList: (params: any) =>
    request.get('/report/remaining-product', { params }),
  getReportRemainingListExcel: (params: any) =>
    request.get('/report/remaining-product/excel', { params }),
  getReportIncomeList: (params: any) =>
    request.get('/report/gross-income', { params }),
  getReportIncomeListExcel: (params: any) =>
    request.get('/report/gross-income/excel', { params })
};

export default reportInvoiceService;

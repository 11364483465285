/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Form } from 'react-bootstrap';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import legalEntitySettingsService from 'service/legalEntitySettings';
import Button from 'components/base/Button';
import transactionService from 'service/transaction';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';
import CompareCashTransactionTable from './CompareCashTransactionTable';

export const cashTransactionBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'cash_transaction',
    active: true
  }
];

export type SettelementData = {
  client: string;
  type: string;
  status: string;
  bg_color: string;
  text_color: string;
  transaction_type: string;
  total_amount: string;
  change_amount: string;
  receipt_amount: string;
  guid: string;
};

const CashTransaction = () => {
  const { t, i18n } = useTranslation();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [settlementData, setSettlementData] = useState<any>([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selecetedTargetId, setSelectedTargetId] = useState('');
  const [openSelected, setOpenSelected] = useState(false);
  const [amountFrom, setAmountFrom] = useState('');
  const [amountTo, setAmountTo] = useState('');
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [typeId, setTypeId] = useState<any>('');

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const typeOption = [
    { label: t('SALE'), value: 'SALE' },
    { label: t('SALE_RETURN'), value: 'SALE_RETURN' },
    { label: t('DEBIT_CREDIT'), valDEBIT_CREDITue: 'SALE' },
    { label: t('DEBIT_CREDIT_RETURN'), value: 'DEBIT_CREDIT_RETURN' },
    { label: t('CONVERSION_IN'), value: 'CONVERSION_IN' },
    { label: t('CONVERSION_OUT'), value: 'CONVERSION_OUT' },
    { label: t('EXPENSE'), value: 'EXPENSE' }
  ];
  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const adjustedDateFrom = dateFrom || todayStart;
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? todayEnd + 86400000
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, isFetching, isLoading } = useQuery(
    [
      'GET_TRANSACTION',
      currentPage,
      amountFrom,
      amountTo,
      adjustedDateFrom,
      adjustedDateTo,
      typeId
    ],
    () => {
      const res = transactionService
        .getList({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo,
          amount_from: +amountFrom,
          amount_to: +amountTo,
          transaction_type: typeId
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!currentPage ||
        !!adjustedDateFrom ||
        !!adjustedDateTo ||
        !!amountFrom ||
        !!amountTo ||
        !!typeId
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';
    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resultData =
      data?.transactions?.map((items: any) => {
        return {
          total_amount: items?.total_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.total_amount)
                .replace(/,/g, ' ')
            : 0,
          change_amount: items?.change_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.change_amount)
                .replace(/,/g, ' ')
            : 0,
          receipt_amount: items?.receipt_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.receipt_amount)
                .replace(/,/g, ' ')
            : 0,
          transaction_type: items?.transaction_type,
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          status: items?.status?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          guid: items?.id
        };
      }) ?? [];

    setSettlementData(resultData);
  }, [data, t, i18n?.language, dataSettings]);

  const handleMoveToTop = (id: any) => {
    setOpenSelected(true);
    setSelectedTargetId(id);
  };

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (!dateFrom) {
      setDateFrom(todayStart);
    }
    if (!dateTo) {
      setDateTo(todayEnd);
    }
  }, [dateFrom, dateTo, setDateFrom, setDateTo]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action-1',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            className="p-2"
            onClick={() => handleMoveToTop(rowData.row.original?.guid)}
          >
            <FeatherIcon icon="arrow-up" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'client',
      header: t('client'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'type',
      header: t('transaction_type'),
      cell: rowData => {
        /* prettier-ignore */
        return (
          <div>
            {rowData?.row?.original?.transaction_type === 'SALE'
              ? t('SALE')
              : rowData?.row?.original?.transaction_type === 'SALE_RETURN'
                ? t('SALE_RETURN')
                : rowData?.row?.original?.transaction_type === 'DEBIT_CREDIT'
                  ? t('DEBIT_CREDIT')
                  : rowData?.row?.original?.transaction_type ===
                      'DEBIT_CREDIT_RETURN'
                    ? t('DEBIT_CREDIT_RETURN')
                    : rowData?.row?.original?.transaction_type ===
                        'CONVERSION_IN'
                      ? t('CONVERSION_IN')
                      : rowData?.row?.original?.transaction_type ===
                          'CONVERSION_OUT'
                        ? t('CONVERSION_OUT')
                        : rowData?.row?.original?.transaction_type === 'EXPENSE'
                          ? t('EXPENSE')
                          : t('UNKNOWN')}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_amount',
      header: t('document_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'receipt_amount',
      header: t('check_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'change_amount',
      header: t('surrender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status, bg_color, text_color } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          новый: { bg: '#1d4289', text: '#ffffff' },
          'Частична возврашен': { bg: '#fef3c7', text: '#92400e' },
          Yangi: { bg: '#fef3c7', text: '#92400e' },
          'Qisman qaytarilgan': { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
          bg_color || defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor =
          text_color || defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
    //  {
    //    id: 'action',
    //    cell: rowData => (
    //      <div>
    //        <OverlayTrigger
    //          placement="top"
    //          overlay={
    //            <Tooltip style={{ position: 'fixed' }}>{t('return')}</Tooltip>
    //          }
    //        >
    //          <div>
    //            <Button
    //              variant="hover"
    //              className="p-2"
    //              disabled={rowData?.row?.original?.can_update}
    //              onClick={() => {
    //                setOpenReturn(true);
    //                setSelectedItems(rowData?.row?.original);
    //              }}
    //            >
    //              <FontAwesomeIcon icon={faArrowRotateBack} className="fs-7" />
    //            </Button>
    //          </div>
    //        </OverlayTrigger>
    //      </div>
    //    ),
    //    meta: {
    //      headerProps: { style: { width: '5%' } },
    //      cellProps: { className: 'text-center' }
    //    }
    //  }
  ];

  const table = useAdvanceTable({
    data: settlementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div ref={tableRef}>
      <PageBreadcrumb items={cashTransactionBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('cash_transaction')}</h2>

        <div className="d-flex gap-2">
          <Form.Group>
            <div className="react-select-container position-relative">
              <Form.Floating>
                <Form.Select
                  onChange={(e: any) => {
                    const selectedOption = typeOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setTypeId(selectedOption?.value);
                    }
                  }}
                >
                  <option className="d-none" value=""></option>
                  {typeOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('buyer')}</label>
              </Form.Floating>
            </div>
          </Form.Group>

          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('amount_from')}
              onFocus={e => e.target.select()}
              value={amountFrom}
              onChange={e => setAmountFrom(e?.target?.value)}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">{t('amount_from')}</label>
          </Form.Floating>

          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('amount_to')}
              onFocus={e => e.target.select()}
              value={amountTo}
              onChange={e => setAmountTo(e?.target?.value)}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">{t('amount_to')}</label>
          </Form.Floating>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateFrom}
                onChange={handleStartDateChange}
                placeholder={t('date_from')}
              />
              <label htmlFor="floatingInputCustom">{t('date_from')}</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: getFlatpickrLocale(),
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateTo}
                onChange={handleEndDateChange}
                placeholder={t('date_to')}
              />
              <label htmlFor="floatingInputCustom">{t('date_to')}</label>
            </Form.Floating>
          </Form.Group>
        </div>
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              isLoading={loading}
            />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </AdvanceTableProvider>

        <Modal
          show={openSelected}
          onHide={() => {
            setOpenSelected(false);
            setSelectedTargetId('');
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
          contentClassName="border border-300"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('cash_transaction')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CompareCashTransactionTable
              selecetedTargetId={selecetedTargetId}
              openSelected={openSelected}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CashTransaction;

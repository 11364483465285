/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import timezoneService from 'service/timezone';
import legalEntitySettingsService from 'service/legalEntitySettings';

export const settingsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'settings',
    active: true
  }
];

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => field.onChange(e.target.checked)}
    />
  );
};

const AdminSettings = () => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, control, register, reset } = useForm();
  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);
  const [timeZoneOption, setTimeZoneOption] = useState([]);

  //Get Legal Entity Settings
  const { refetch } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({ offset: 0, limit: 10 })
      .then((res: any) => {
        if (res) {
          const resetData = res?.legal_entity_settings?.map((items: any) => ({
            time_zone: items?.time_zone_id,
            price_mark: items?.extra_charge,
            price_mark_whosale: items?.extra_charge_wholesale,
            weight_product_prefix: items?.weight_product_prefix,
            piece_product_prefix: items?.piece_product_prefix,
            legal_entity_id: items?.legal_entity_id,
            is_disabled_auto_refresh_product: items?.is_auto_tab,
            is_sale_in_minus: items?.sale_in_negative,
            is_show_price: items?.show_price,
            is_forced: items?.is_forced,
            is_tab_inputs_all: items?.is_tab_inputs_all,
            is_force_quantity: items?.is_force_quantity,
            is_auto_refresh_product: items?.is_auto_refresh_product,
            guid: items?.id
          }));

          reset(resetData[0]);
        }
      });
  });

  //GET Time to options
  useQuery(['GET_TIME_ZONE'], async () => {
    await timezoneService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.time_zones?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setTimeZoneOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    const createData = {
      extra_charge: +data?.price_mark,
      extra_charge_wholesale: +data?.price_mark_whosale,
      weight_product_prefix: +data?.weight_product_prefix,
      piece_product_prefix: +data?.piece_product_prefix,
      time_zone_id: data?.time_zone,
      legal_entity_id: data?.legal_entity_id,
      is_auto_tab: data?.is_disabled_auto_refresh_product,
      sale_in_negative: data?.is_sale_in_minus,
      show_price: data?.is_show_price,
      is_forced: data?.is_forced,
      is_tab_inputs_all: data?.is_tab_inputs_all,
      is_force_quantity: data?.is_force_quantity,
      is_auto_refresh_product: data?.is_auto_refresh_product,
      id: data?.guid
    };

    legalEntitySettingsService
      .updateStatus(data?.guid, { ...createData })
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('settings')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
          setLoading(false);
        }
      });
  };
  return (
    <div>
      <PageBreadcrumb items={settingsBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('settings')}</h2>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex flex-column w-75 h-100"
        >
          <h4 className="mb-3">{t('general')}</h4>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">{t('time_zone')}</p>
            <Form.Group className="w-25">
              <Controller
                name="time_zone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {timeZoneOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('time_zone')}
                    </label>
                  </Form.Floating>
                )}
              />
            </Form.Group>
          </div>

          <h4 className="mb-3">{t('purchase')}</h4>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('markup_goods')}</p>
            <Form.Floating className="w-25">
              <Form.Control
                {...register('price_mark')}
                type="text"
                placeholder={t('markup_goods')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('markup_goods')}</label>
            </Form.Floating>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('markup_wholesale_goods')}</p>
            <Form.Floating className="w-25">
              <Form.Control
                {...register('price_mark_whosale')}
                type="text"
                placeholder={t('markup_wholesale_goods')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">
                {t('markup_wholesale_goods')}
              </label>
            </Form.Floating>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('compulsory_markup_goods')}</p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_forced"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">
              {t('automatic_update_selling_price_residual_goods')}
            </p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_auto_refresh_product"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">
              {t('disabling_automatic_entry_product_quantities')}
            </p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_disabled_auto_refresh_product"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('skip_wholesale_price_change')}</p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_tab_inputs_all"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">
              {t('force_quantity_specified_searching_product')}
            </p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_force_quantity"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <h4 className="mb-3">{t('sale_goods')}</h4>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('sales_goods_loss')}</p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_sale_in_minus"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">{t('show_entry_price')}</p>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_show_price"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label={t('of_on')} />
                )}
              />
            </Form.Group>
          </div>

          <h4 className="mb-3">{t('scales')}</h4>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('weight_prefix')}</p>
            <Form.Floating className="w-25">
              <Form.Control
                {...register('weight_product_prefix')}
                type="text"
                placeholder={t('weight_prefix')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('weight_prefix')}</label>
            </Form.Floating>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="mb-0">{t('piece_prefix')}</p>
            <Form.Floating className="w-25">
              <Form.Control
                {...register('piece_product_prefix')}
                type="text"
                placeholder={t('piece_prefix')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('piece_prefix')}</label>
            </Form.Floating>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              style={{ height: '48px', marginTop: '30px' }}
              variant="primary"
              loading={loading}
            >
              {t('save')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AdminSettings;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo, useRef } from 'react';

import { pageToOffset } from 'utils/pageToOffset';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import legalEntitySettingsService from 'service/legalEntitySettings';
import transactionService from 'service/transaction';

export type SettelementData = {
  client: string;
  type: string;
  status: string;
  bg_color: string;
  text_color: string;
  transaction_type: string;
  total_amount: string;
  change_amount: string;
  receipt_amount: string;
  movedToTop: string;
  guid: string;
};

interface CompareCashTransactionTableProps {
  selecetedTargetId?: string;
  openSelected?: any;
}

const CompareCashTransactionTable = ({
  selecetedTargetId,
  openSelected
}: CompareCashTransactionTableProps) => {
  const { t, i18n } = useTranslation();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [pageClicked, setPageClicked] = useState(false);

  const hasSetDefaultPage = useRef(false);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, isFetching, isLoading } = useQuery(
    ['GET_TRANSACTION', currentPage, selecetedTargetId, pageClicked],
    () => {
      const res = transactionService
        .getList({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          sort: 1,
          target_id: !pageClicked ? selecetedTargetId : undefined
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    { enabled: !!currentPage || !!selecetedTargetId }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));

      if (!hasSetDefaultPage.current && data?.target_offset !== undefined) {
        const defaultPage = Math.floor(data.target_offset / 10) + 1;
        setCurrentPage(defaultPage || 0);
        hasSetDefaultPage.current = true; // Ensure this is only executed once
      }
    }
  }, [data]);

  useEffect(() => {
    if (openSelected) {
      setCurrentPage(1); // Reset to initial page
      hasSetDefaultPage.current = false; // Allow default page logic to run again
    }
  }, [openSelected]);

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';
    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resultData =
      data?.transactions?.map((items: any) => {
        return {
          total_amount: items?.total_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.total_amount)
                .replace(/,/g, ' ')
            : 0,
          change_amount: items?.change_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.change_amount)
                .replace(/,/g, ' ')
            : 0,
          receipt_amount: items?.receipt_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.receipt_amount)
                .replace(/,/g, ' ')
            : 0,
          transaction_type: items?.transaction_type,
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          status: items?.status?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          movedToTop: items?.id === selecetedTargetId,
          guid: items?.id
        };
      }) ?? [];

    setSettlementData(resultData);
  }, [data, t, i18n?.language, dataSettings, selecetedTargetId]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      accessorKey: 'client',
      header: t('client'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'type',
      header: t('transaction_type'),
      cell: rowData => {
        /* prettier-ignore */
        return (
          <div>
            {rowData?.row?.original?.transaction_type === 'SALE'
              ? t('SALE')
              : rowData?.row?.original?.transaction_type === 'SALE_RETURN'
                ? t('SALE_RETURN')
                : rowData?.row?.original?.transaction_type === 'DEBIT_CREDIT'
                  ? t('DEBIT_CREDIT')
                  : rowData?.row?.original?.transaction_type ===
                      'DEBIT_CREDIT_RETURN'
                    ? t('DEBIT_CREDIT_RETURN')
                    : rowData?.row?.original?.transaction_type ===
                        'CONVERSION_IN'
                      ? t('CONVERSION_IN')
                      : rowData?.row?.original?.transaction_type ===
                          'CONVERSION_OUT'
                        ? t('CONVERSION_OUT')
                        : rowData?.row?.original?.transaction_type === 'EXPENSE'
                          ? t('EXPENSE')
                          : t('UNKNOWN')}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_amount',
      header: t('document_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'receipt_amount',
      header: t('check_amount'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'change_amount',
      header: t('surrender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status, bg_color, text_color } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          новый: { bg: '#1d4289', text: '#ffffff' },
          'Частична возврашен': { bg: '#fef3c7', text: '#92400e' },
          Yangi: { bg: '#fef3c7', text: '#92400e' },
          'Qisman qaytarilgan': { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
          bg_color || defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor =
          text_color || defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settlementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={isLoading || isFetching}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(event, page) => {
              if (page) {
                setPageClicked(true);
              }
            }}
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default CompareCashTransactionTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import languageService from 'service/languages';
import { showAlert } from 'store/alert/alert.thunk';
import legalEntityArticleService from 'service/legalEntityArticle';
import accountsService from 'service/account';

interface CreateArticlesProps {
  setOpenArticle: (show: boolean) => void;
  selectedArticleId?: any;
  refetch?: any;
  setSelectedArticleId?: any;
}

const CreateArticles = ({
  setOpenArticle,
  selectedArticleId,
  refetch,
  setSelectedArticleId
}: CreateArticlesProps) => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [accountOption, setAccountOption] = useState([]);
  const [selectedAccountName, setSelectedAccountName] = useState<any>(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //GET Account to options
  useQuery(['GET_ACCOUNT'], async () => {
    await accountsService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.accounts?.map((account: any) => ({
          value: account.id,
          label: account.name?.[i18n?.language]
        }));
        setAccountOption(options);
      });
  });

  //Get by id
  const fetchData = () => {
    if (selectedArticleId === '') return setLoading(false);

    legalEntityArticleService
      .getById(selectedArticleId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          accounts_name: res?.account_id,

          status: res?.status ?? false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedArticleId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter((key: any) =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach((key: any) => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      account_id: data?.accounts_name,
      status: data?.status === 'true' ? true : false
    };

    legalEntityArticleService
      .create(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('financial_account')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenArticle(false);
        setLoading(false);
        setSelectedArticleId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      account_id: data?.accounts_name,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedArticleId
    };

    legalEntityArticleService
      .update(selectedArticleId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenArticle(false);
        setSelectedArticleId('');
      });
  };

  useEffect(() => {
    if (selectedArticleId !== '') {
      fetchData();
    }
  }, [langData, accountOption, selectedArticleId]);

  useEffect(() => {
    if (selectedAccountName && inputRef.current) {
      inputRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current, langData, selectedAccountName]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="accounts_name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      ref={inputRef}
                      onChange={(e: any) => {
                        const selectedOption = accountOption.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedOption) {
                          setSelectedAccountName(selectedOption);
                        }

                        field.onChange(e);
                      }}
                      isInvalid={!!errors.accounts_name}
                    >
                      <option className="d-none" value=""></option>
                      {accountOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('account_name')}
                    </label>
                    {errors?.accounts_name && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        {langData &&
          langData.map((item: any, index: number) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Controller
                  name={`name_${item.char_code.toLowerCase()}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Floating className="md-4">
                      <Form.Control
                        {...field}
                        ref={
                          selectedAccountName &&
                          (index === 0 ? inputRef : undefined)
                        }
                        type="text"
                        placeholder={`Наименование статьи ${item.char_code}`}
                        onFocus={e => e.target.select()}
                        isInvalid={
                          !!errors[`name_${item.char_code.toLowerCase()}`]
                        }
                      />
                      <label htmlFor="floatingInputCustom">
                        {t('name_financial_account')} ( {item.char_code} )
                      </label>
                      {errors[`name_${item.char_code.toLowerCase()}`] && (
                        <Form.Control.Feedback type="invalid">
                          {t('required_field')}
                        </Form.Control.Feedback>
                      )}
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateArticles;

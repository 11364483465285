/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import activityService from 'service/activity';
import countryService from 'service/country';
import currencyService from 'service/currency';
import areaService from 'service/district';
import regionService from 'service/region';
import legalEntityStatusService from 'service/legalEntityStatus';
import PhoneNumberInput from 'components/forms/PhoneNumberInput';
import useCountryUnicode from 'hooks/useCountryUnicode';
import legalEntityService from 'service/legalEntity';
import { showAlert } from 'store/alert/alert.thunk';
import accessesService from 'service/legalEntityAccess';
import ImageUpload from 'components/common/ImageUpload';

import './styles.scss';

interface CreateLegalEntityProps {
  setOpenLegalCreate: (show: boolean) => void;
  selectedLegalId?: any;
  refetch?: any;
  setSelectedLegalId?: any;
}

const CreateLegalEntity = ({
  setOpenLegalCreate,
  selectedLegalId,
  refetch,
  setSelectedLegalId
}: CreateLegalEntityProps) => {
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const unicode = useCountryUnicode();
  const dispatch: Dispatch<any> = useDispatch();
  const hasFetchedData = useRef(false);

  const system_level = useSelector(
    (state: any) => state?.auth?.user?.role?.system_level
  );

  const [loading, setLoading] = useState(false);

  const [countryOption, setCountryOption] = useState([]);
  const [activityOption, setActivityOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [legalStatusOption, setLegalStatusOption] = useState([]);
  const [legalAccessOption, setLegalAccessOption] = useState([]);
  const [typeOption, setTypeOption] = useState<
    { value: string; label: string }[]
  >([]);

  const inputRef = useRef<HTMLInputElement | any>();

  //GET Activity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((activity: any) => ({
          value: activity.id,
          label: activity.name?.[i18n?.language]
        }));
        setActivityOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.countries?.map((country: any) => ({
          value: country.id,
          label: country.name?.[i18n?.language]
        }));
        setCountryOption(options);
      });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //GET Area to options
  useQuery(['GET_AREA'], async () => {
    await areaService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.districts?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setAreaOption(options);
      });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.regions?.map((region: any) => ({
          value: region.id,
          label: region.name?.[i18n?.language]
        }));
        setRegionOption(options);
      });
  });

  //GET Status to options
  useQuery(['GET_LEGAL_ENTITY_STATUS'], async () => {
    await legalEntityStatusService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_statuses?.map((status: any) => ({
          value: status.id,
          label: status.name?.[i18n?.language]
        }));
        setLegalStatusOption(options);
      });
  });

  //GET Access to options
  useQuery(['GET_ACCESS'], async () => {
    await accessesService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_accesses?.map((access: any) => ({
          value: access.id,
          label: access.name?.[i18n?.language]
        }));
        setLegalAccessOption(options);
      });
  });

  const fetchData = useMemo(() => {
    return () => {
      if (selectedLegalId === '') return setLoading(false);

      legalEntityService
        .getLegalEntityById(selectedLegalId)
        .then((res: any) => {
          const computed = {
            physical_name: res?.name,
            address: res?.address,
            phone: res?.phone_number,
            type_physical_name: res.legal_entity_type,
            kinds: res?.activity_kind_id,
            country: res?.country_id,
            def_currency: res?.currency?.id,
            area: res?.district_id,
            region: res?.region_id,
            access: res?.legal_entity_access_id,
            status: res?.legal_entity_status_id,
            type_interaction: res?.legal_entity_access.id,
            logo_url: process.env.REACT_APP_CDN_URL + res.logo_url || ''
          };

          return computed;
        })
        .then(computed => {
          reset(computed);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };
  }, [selectedLegalId]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedLegalId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.physical_name,
      activity_kind_id: data?.kinds,
      address: data?.address,
      country_id: data?.country,
      currency_id: data?.def_currency,
      district_id: data?.area,
      legal_entity_status_id: data?.status,
      legal_entity_access_id: data?.type_interaction,
      legal_entity_type: data?.type_physical_name,
      phone_number: data?.phone,
      region_id: data?.region,
      logo_url: data?.logo_url
        ? data.logo_url.substring(data.logo_url.indexOf('/photos/'))
        : ''
    };

    legalEntityService
      .createLegalEntity(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('legal_entities')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setOpenLegalCreate(false);
        setLoading(false);
        setSelectedLegalId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.physical_name,
      activity_kind_id: data?.kinds,
      address: data?.address,
      country_id: data?.country,
      currency_id: data?.def_currency,
      district_id: data?.area,
      legal_entity_access_id: data?.type_interaction,
      legal_entity_status_id: data?.status,
      legal_entity_type: data?.type_physical_name,
      phone_number: data?.phone,
      region_id: data?.region,
      logo_url: data?.logo_url
        ? data.logo_url.substring(data.logo_url.indexOf('/photos/'))
        : '',
      id: selectedLegalId
    };
    legalEntityService
      .updateLegalEntity(selectedLegalId, updateData)
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenLegalCreate(false);
        setSelectedLegalId('');
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    let updatedTypeOption: { value: string; label: string }[] = [];

    if (system_level === 'ROOT') {
      updatedTypeOption = [
        {
          value: 'HOLDING',
          label: t('holding')
        },
        {
          value: 'BRAND',
          label: t('brand')
        },
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'HOLDING') {
      updatedTypeOption = [
        {
          value: 'BRAND',
          label: t('brand')
        },
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'BRAND') {
      updatedTypeOption = [
        {
          value: 'DEALER',
          label: t('dealer')
        },
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'DEALER') {
      updatedTypeOption = [
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    } else if (system_level === 'STORE') {
      updatedTypeOption = [
        {
          value: 'STORE',
          label: t('store')
        }
      ];
    }

    setTypeOption([...updatedTypeOption]);
  }, [system_level]);

  useEffect(() => {
    if (selectedLegalId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedLegalId]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="form-cls">
        <Row className="mb-2">
          <Col>
            <Controller
              name="physical_name"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder="Physical name"
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.physical_name}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('name_legal_entity')}
                  </label>
                  {errors?.physical_name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Controller
              name="type_physical_name"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    isInvalid={!!errors.type_physical_name}
                  >
                    {!field.value && <option value="" disabled hidden></option>}
                    {typeOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('legal_entity_type')}
                  </label>

                  {errors?.type_physical_name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="type_interaction"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {legalAccessOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('interaction_type')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="kinds"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.kinds}>
                      <option className="d-none" value=""></option>
                      {activityOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('activity_type')}
                    </label>
                    {errors?.kinds && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="def_currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('default_currency')}
                    </label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {legalStatusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.country}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('country')}</label>
                    {errors?.country && (
                      <Form.Control.Feedback type="invalid">
                        {t('required_field')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {regionOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('region')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {areaOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('area')}</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('address')}
                type="text"
                placeholder="address"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('address')}</label>
            </Form.Floating>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <PhoneNumberInput field={field} unicode={unicode} />
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('logo')}</Form.Label>
            <Controller
              name="logo_url"
              control={control}
              defaultValue=""
              render={({ field }) => <ImageUpload {...field} />}
            />
          </Col>
        </Row>
        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateLegalEntity;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ComingResult } from 'data/returning';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import returnItemService from 'service/returnItemService';
import returnInService from 'service/returnIn';
import returnService from 'service/return';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import './styles.scss';

const ComingResultReturning = () => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();
  const returningId = pathname.split('/').pop();
  const dispatch: Dispatch<any> = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  //Fetch Users By ID
  const { refetch: refetchIn } = useQuery(
    ['GET_RETURN_IN_CAN_UPDATE', returningId],
    async () => {
      if (returningId) {
        const res = await returnInService
          .getList({
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.returns;
          });

        if (res) {
          const updatedRes = res?.find((el: any) => el?.id === returningId);
          setCanUpdate(updatedRes?.can_update ?? false);
        }
      }
    },
    { enabled: !!returningId }
  );

  const { data, refetch } = useQuery(
    ['GET_RETURN_ITEM_IN', returningId],
    () => {
      if (returningId) {
        const res = returnItemService
          .getReturnItemInById(returningId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!returningId }
  );

  const comingResultData: ComingResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        const historyCount =
          items?.price_in && items?.quantity
            ? +items.price_in * +items.quantity
            : 0;
        return {
          guid: items?.id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product_short_name,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          barcode: items?.barcode,
          currency: items?.currency?.name?.[i18n?.language],
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity,
          status: items?.status?.name?.[i18n?.language],
          cost: items?.price_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.price_in)
                .replace(/,/g, ' ')
            : 0,
          remainder: items?.total_quantity ?? 0,
          /* prettier-ignore */
          history: historyCount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(historyCount)
                .replace(/,/g, ' ')
            : 0
        };
      }) ?? [];

    return resultData;
  }, [data, t, i18n?.language]);

  const comingResultTableColumns: ColumnDef<ComingResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: t('wholesale_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: t('application_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'history',
      header: t('final_purchase_price'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          Завершен: { bg: '#BBFBD0', text: '#119C2B' },
          Отправлен: { bg: '#fef3c7', text: '#92400e' },
          Bajarildi: { bg: '#BBFBD0', text: '#119C2B' },
          "Jo'natilgan": { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'returns',
      url: '/returning/outgoing'
    },
    {
      label: 'incoming',
      url: '/returning/coming'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const table = useAdvanceTable({
    data: comingResultData,
    columns: comingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = comingResultData.reduce((acc: any, item: any) => {
    const allCost =
      item?.history === undefined ? 0 : +item?.history?.split(' ')?.join('');
    return acc + allCost;
  }, 0);

  const handleSave = () => {
    setSaveLoading(true);
    returnService.getReturnById(returningId).then((res: any) => {
      if (res) {
        returnInService
          ?.updateReturnIn(returningId, {
            ...res,
            status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
          })
          .then((inresponse: any) => {
            if (inresponse) {
              dispatch(
                showAlert({
                  title: t('answered_successfully'),
                  type: 'success'
                })
              );
              refetch();
              refetchIn();
            }
          });
      }
    });
  };

  const handleFinished = () => {
    setFinishLoading(true);
    if (returningId) {
      returnService.getReturnById(returningId).then((res: any) => {
        if (res) {
          returnInService
            ?.updateReturnIn(returningId, {
              ...res,
              status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
            })
            .then((inresponse: any) => {
              if (inresponse) {
                dispatch(
                  showAlert({
                    title: t('rejected_successfully'),
                    type: 'success'
                  })
                );
                refetch();
                refetchIn();
              }
            });
        }
      });
    }
  };

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        <div className="footer-ret-coming">
          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount')}</p>
            <span>
              {sum
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sum)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="main-btn-group">
            <Button
              onClick={() => handleSave()}
              disabled={!canUpdate}
              variant="primary"
              loading={saveLoading}
            >
              {t('answer')}
            </Button>
            <Button
              onClick={() => handleFinished()}
              disabled={!canUpdate}
              variant="danger"
              loading={finishLoading}
            >
              {t('reject')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingResultReturning;

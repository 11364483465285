/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import currencyService from 'service/currency';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';
import conversionService from 'service/conversion';
import customersService from 'service/customer';

interface CreateConversionProps {
  setOpenConversion: (show: boolean) => void;
  refetch?: any;
}

const CreateConversion = ({
  setOpenConversion,
  refetch
}: CreateConversionProps) => {
  const { t, i18n } = useTranslation();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({});

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [mainCurrencyOptions, setMainCurrencyOptions] = useState<any[]>([]);
  const [unitsOptions, setUnitsOptions] = useState<any[]>([]);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [selectedUnitsId, setSelectedUnitsId] = useState('');
  const [selectedMainCurrencyId, setSelectedMainCurrencyId] = useState('');
  const [converstionRateValue, setConversionRateValue] = useState(0);

  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setMainCurrencyOptions(options);
        setUnitsOptions(options);
      });
  });

  useQuery(['GET_CUSTOMER'], async () => {
    await customersService
      .getList({ offset: 0, limit: 1000 })
      .then((res: any) => {
        const options = res?.data?.users?.map((options: any) => ({
          value: options.id,
          label: options.first_name + ' ' + options?.last_name
        }));
        setCustomerOptions(options);
      });
  });

  useQuery(
    [
      'GET_LEGAL_ENTITY_CURRENCY_RATE_CONVERSION',
      selectedUnitsId,
      selectedMainCurrencyId
    ],
    async () => {
      if (selectedUnitsId !== '' && selectedMainCurrencyId !== '') {
        await legalEntityCurrencyRateService
          .getListConversion({
            offset: 0,
            limit: 100,
            income_currency_id: selectedMainCurrencyId,
            outcome_currency_id: selectedUnitsId
          })
          .then((res: any) => {
            if (res) {
              setConversionRateValue(
                res?.legal_entity_currency_conversion_rates[0]
                  ?.outcome_amount ?? 0
              );
            }
          });
      }
    },
    { enabled: !!selectedUnitsId || !!selectedMainCurrencyId }
  );

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    if (cashboxId === '') {
      dispatch(showAlert({ title: t('shift_not_open') }));
      setLoading(false);
      return;
    }

    const createData = {
      amount_in: +data?.base || 0,
      amount_out: (+data?.base || 0) * (converstionRateValue || 0),
      cashbox_id: cashboxId,
      from_currency_id: data?.main_currency,
      to_currency_id: data?.units,
      user_id: data?.user?.value
    };

    conversionService?.createRate(createData)?.then((res: any) => {
      if (res) {
        dispatch(
          showAlert({
            title: `${t('currency_conversion')} ${t('successfully_added')}`,
            type: 'success'
          })
        );

        refetch();
        setOpenConversion(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const baseValue = watch('base');

  useEffect(() => {
    if (converstionRateValue > 0 && baseValue !== undefined) {
      const convertibleValue = (+baseValue || 0) * (converstionRateValue || 0);
      setValue(
        'convertible',
        convertibleValue
          ? new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 4,
              useGrouping: true
            })
              .format(convertibleValue)
              .replace(/,/g, ' ')
          : '0'
      );
    }
  }, [converstionRateValue, baseValue]);

  return (
    <>
      {/* // eslint-disable-next-line no-unsafe-optional-chaining */}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Form.Group className="mb-2">
          <Form.Label>{t('contacts')}</Form.Label>
          <div className="react-select-container">
            <Controller
              name="user"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  closeMenuOnSelect={true}
                  classNamePrefix="react-select"
                  placeholder={t?.('contacts') || ''}
                  options={customerOptions || []}
                  styles={{
                    control: base => ({
                      ...base,
                      fontSize: '12.8px',
                      height: '48px'
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: '12.8px'
                    }),
                    menuPortal: base => ({
                      ...base,
                      fontSize: '12.8px'
                    })
                  }}
                />
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-2">
          <Controller
            name="base"
            rules={{
              required: true
            }}
            control={control}
            render={({ field }) => (
              <Form.Floating className="w-100">
                <Form.Control
                  {...field}
                  ref={inputRef}
                  type="text"
                  placeholder="Base currency"
                  onFocus={e => e.target.select()}
                  isInvalid={!!errors?.base}
                />
                <label htmlFor="floatingInputCustom">{t('received')}</label>
                {errors?.base && (
                  <span
                    style={{
                      marginTop: '2px',
                      color: 'red',
                      fontSize: '12px'
                    }}
                  >
                    {t('required_field')}
                  </span>
                )}
              </Form.Floating>
            )}
          />
        </Form.Group>

        <Form.Group className="mb-2">
          <div className="react-select-container">
            <Controller
              name="main_currency"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = mainCurrencyOptions.find(
                        (option: any) => option.value === e.target.value
                      );

                      if (selectedOption) {
                        setSelectedMainCurrencyId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                    isInvalid={!!errors.main_currency}
                  >
                    <option className="d-none" value=""></option>
                    {mainCurrencyOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">
                    {t('main_currency')}
                  </label>
                </Form.Floating>
              )}
            />
            {errors?.main_currency && (
              <span
                style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
              >
                {t('required_field')}
              </span>
            )}
          </div>
        </Form.Group>

        <Form.Group className="mb-2 d-flex justify-content-center">
          <FeatherIcon icon="chevrons-down" />
        </Form.Group>

        <Form.Group className="mb-2">
          <div className="react-select-container">
            <Controller
              name="units"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = unitsOptions.find(
                        (option: any) => option.value === e.target.value
                      );

                      if (selectedOption) {
                        setSelectedUnitsId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                    isInvalid={!!errors.units}
                  >
                    <option className="d-none" value=""></option>
                    {unitsOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <label htmlFor="floatingInputCustom">
                    {t('convertible_currency_unit')}
                  </label>
                </Form.Floating>
              )}
            />
            {errors?.units && (
              <span
                style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
              >
                {t('required_field')}
              </span>
            )}
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Controller
            name="convertible"
            control={control}
            render={({ field }) => (
              <Form.Floating className="w-100">
                <Form.Control
                  disabled={true}
                  {...field}
                  type="text"
                  placeholder="Base convertible"
                  onFocus={e => e.target.select()}
                  autoComplete="off"
                />
                <label htmlFor="floatingInputCustom">{t('send')}</label>
              </Form.Floating>
            )}
          />
        </Form.Group>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default CreateConversion;

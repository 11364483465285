/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import StockTable from './StockTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { stockBreadcrumbItems } from 'data/stock';
import { StockType } from 'data/stock';
import CreateStock from './CreateStock';
import warehouseService from 'service/warehouse';

const Stock = () => {
  const { t } = useTranslation();

  const [openStock, setOpenStock] = useState(false);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');

  //Get Warehouse
  const { data, refetch } = useQuery(['GET_WAREHOUSE'], () => {
    const res = warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.ware_houses);
    return res;
  });

  const stockData: StockType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t]);

  const stockTableColumns: ColumnDef<StockType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="hover"
            onClick={() => {
              setOpenStock(true);
              setSelectedWarehouseId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('warehouse_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' },
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: stockData,
    columns: stockTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={stockBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('warehouse')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenStock(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <StockTable />
          </div>

          <Offcanvas
            show={openStock}
            onHide={() => {
              setOpenStock(false);
              setSelectedWarehouseId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateStock
                setOpenStock={setOpenStock}
                selectedWarehouseId={selectedWarehouseId}
                refetch={refetch}
                setSelectedWarehouseId={setSelectedWarehouseId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Stock;

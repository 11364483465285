/* eslint-disable @typescript-eslint/no-explicit-any */
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from 'react-to-print';
import { useRef, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import ComponentToPrint from './ComponentToPrint';
import Button from 'components/base/Button';
import cashboxService from 'service/cashbox';
import './style.scss';

interface CashInfoCheckProps {
  selectedId?: any;
  currencyId?: any;
  selectedDateClose?: any;
  selectedDateOpen?: any;
}

const CashInfoCheck = ({
  selectedId,
  currencyId,
  selectedDateOpen,
  selectedDateClose
}: CashInfoCheckProps) => {
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);
  const [pageStyle, setPageStyle] = useState<string>('');

  //Get Product Search
  const { data } = useQuery(
    ['GET_CASHBOX', selectedId, currencyId],
    async () => {
      if (selectedId !== '' && currencyId !== '') {
        const res = await cashboxService
          .getXList(selectedId, currencyId, {
            limit: 10,
            offset: 0
          })
          .then((res: any) => {
            return res;
          });
        return res;
      }
    },
    {
      enabled: !!selectedId || !!currencyId
    }
  );
  /* prettier-ignore */
  const conversionamount = data?.x_currency_conversion_report?.amount_in
    ? data?.x_currency_conversion_report?.amount_in
    : data?.x_currency_conversion_report?.amount_out
      ? data?.x_currency_conversion_report?.amount_out
      : 0;

  useEffect(() => {
    if (componentRef.current) {
      setPageStyle(`
        @page {
          size: 80mm auto;
          margin: 0;
          padding: 0 15px 0 0;
        };

       @media print {
          body {
            margin: 0;
            padding: 0 15px 0 0;

          }
          .print-container {
            margin: 0;
            padding: 0 15px 0 0;
            width: 80mm;
            height: auto;
            break-inside: avoid; /* Prevents breaking inside the component */
          }
        }
      `);
    }
  }, [componentRef]);

  return (
    <div className="check-container">
      <div className="check-box">
        <p className="p-0 text-center text-bold">{t('duplicate')}</p>
        <div className="cash-check">
          <ul className="check-list">
            <li>
              {t('availability')} ({t('payment')})
            </li>
            <li>
              {t('availability')} ({t('settlement_with_contact')})
            </li>
            <li>{t('return')}</li>
            <li>
              {t('plastic_card')} ({t('payment')})
            </li>
            <li>
              {t('sale_on_credit')} ({t('payment')})
            </li>
            <li>
              {t('cost')} ({t('payment')})
            </li>
            <li className="text-bold">{t('currency_conversion')}</li>
            <li className="text-bold">{t('discount')}</li>
            <li className="text-bold">{t('total_sale_amount')}</li>
            <li className="text-bold">{t('cash_balance_in_cash')}</li>
            <li className="text-bold">{t('opening_date')}</li>
            <li className="text-bold">{t('opening_date')}</li>
          </ul>

          <ul className="check-cost">
            <li>
              {data?.x_sale_report?.payment_types?.[0]?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_sale_report?.payment_types?.[0]?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_income_report?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_income_report?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_return_report?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_return_report?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_sale_report?.payment_types?.[1]?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_sale_report?.payment_types?.[1]?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_sale_report?.payment_types?.[2]?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_sale_report?.payment_types?.[2]?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_other_report?.expense_amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_other_report?.expense_amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {conversionamount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(conversionamount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_other_report?.discount_amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_other_report?.discount_amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.x_sale_report?.amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.x_sale_report?.amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>
              {data?.cash_amount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(data?.cash_amount)
                    .replace(/,/g, ' ')
                : 0}
            </li>
            <li>{selectedDateOpen ?? 0}</li>
            <li>{selectedDateClose ?? 0}</li>
          </ul>
        </div>
        <div className="d-flex justify-content-end">
          <ReactToPrint
            trigger={() => (
              <Button
                variant="primary"
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center'
                }}
              >
                <FeatherIcon icon="printer" size={18} />
                {t('print')}
              </Button>
            )}
            content={() => componentRef?.current}
            pageStyle={pageStyle}
          />
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="p-1">
          <ComponentToPrint
            data={data}
            selectedDateOpen={selectedDateOpen}
            selectedDateClose={selectedDateClose}
          />
        </div>
      </div>
    </div>
  );
};

export default CashInfoCheck;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import FeatherIcon from 'feather-icons-react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import productSearchService from 'service/productSearch';
import inventoryItemService from 'service/inventoryItem';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';
import unitService from 'service/units';
import './styles.scss';

export type SearchProduct = {
  name: string;
  variation: string;
  barcode: string;
  original_barcode: string;
  unit: string;
};

interface WriteOffInventoryProps {
  info_id?: string;
}

const WireOffNotInLists = ({ info_id }: WriteOffInventoryProps) => {
  const { t, i18n } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [measureunitOption, setMeasureUnitOption] = useState<any>([]);
  const [selectedMeasureUnitId, setSelectedMeasureUnitId] = useState('');
  const [negativeValue, setNegativeValue] = useState(false);
  const [positiveValue, setPositiveValue] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  //GET UNITS to options
  useQuery(['GET_UNITS'], async () => {
    await unitService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.measure_units?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setMeasureUnitOption(options);
      });
  });

  const { data, isFetching, isLoading, refetch } = useQuery(
    [
      'GET_PRODUCT',
      currentPage,
      searchValue,
      selectedMeasureUnitId,
      negativeValue,
      positiveValue
    ],
    () => {
      if (info_id) {
        const res = productSearchService
          .getListProduct({
            offset: pageToOffset(currentPage, 10),
            limit: 10,
            search: searchValue,
            is_positive: positiveValue ? true : undefined,
            is_negative: negativeValue ? true : undefined,
            measure_unit_id: selectedMeasureUnitId,
            not_inventory_id: info_id
          })
          .then((res: any) => {
            setPageCount(Math.ceil(res?.count / 10));
            return res?.products;
          });
        return res;
      }
    },
    {
      enabled:
        !!currentPage ||
        !searchValue ||
        searchValue?.length > 2 ||
        !!selectedMeasureUnitId ||
        !!positiveValue ||
        !!negativeValue
    }
  );

  const loadingData = isLoading || isFetching;

  const generateData: SearchProduct[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          variation: items?.nomenclature?.is_variation ? t('yes') : t('no'),
          barcode: items?.barcode,
          original_barcode: items?.nomenclature?.barcode_original,
          unit: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          quantity: items?.total_quantity,
          nomenclature_id: items?.nomenclature_id,
          currency_id: items?.currency_id,
          total_quantity: items?.total_quantity,
          price_in: items?.price_in ?? 0,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleLikeThero = (row: any) => {
    const createdData = {
      quantity_actual: 0,
      quantity: row?.quantity ?? 0,
      nomenclature_id: row?.nomenclature_id,
      currency_id: row.currency_id,
      price_in: row.price_in ?? 0,
      product_id: row.guid,
      status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512',
      write_off: true
    };

    /* prettier-ignore */
    if (createdData) {
      inventoryItemService
        .createInventoryItem({
          inventory_id: info_id,
          inventory_items: [createdData]
        })
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: t('product_successfully_cancelled'),
                type: 'success'
              })
            );
          }
          refetch();
        });
    }
  };

  const searchProductTableColumns: ColumnDef<SearchProduct>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleLikeThero(rowData.row.original)}
          >
            <FeatherIcon icon="plus" className="cursor-pointer" size={18} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: t('barcode_original'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price_in',
      header: t('price_admission'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: searchProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: false,
    selection: false
  });

  const SubmitAll = () => {
    if (info_id) {
      productSearchService
        .getListProduct({
          search: searchValue,
          is_positive: positiveValue ? true : undefined,
          is_negative: negativeValue ? true : undefined,
          measure_unit_id: selectedMeasureUnitId,
          not_inventory_id: info_id
        })
        .then((res: any) => {
          setAllLoading(true);
          const createdData = res?.products?.map((items: any) => ({
            quantity_actual: 0,
            quantity: items?.total_quantity ?? 0,
            nomenclature_id: items?.nomenclature_id,
            currency_id: items.currency_id,
            price_in: items.price_in,
            product_id: items?.id,
            status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512',
            write_off: true
          }));

          // Function to chunk the data into batches of 300
          const chunkArray = (array: any[], chunkSize: number) => {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
              chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
          };

          // Chunk the createdData into batches of 300
          const dataChunks = chunkArray(createdData, 300);

          const submitChunk = async (chunk: any[], index: number) => {
            setSubmitLoading(true);
            await inventoryItemService
              .createInventoryItem({
                inventory_id: info_id,
                inventory_items: chunk
              })
              .then((res: any) => {
                /* prettier-ignore */
                if (res) {
                  dispatch(
                    showAlert({
                      title: `${t('product_successfully_cancelled')} (${index + 1}/${dataChunks.length})`,
                      type: 'success'
                    })
                  );
                }
              });
          };

          // Function to process all chunks in sequence
          const processChunks = async () => {
            for (let i = 0; i < dataChunks.length; i++) {
              await submitChunk(dataChunks[i], i);
            }
            setAllLoading(false);
            refetch(); // Refetch data after all chunks have been processed
            setSubmitLoading(false);
          };

          // Start processing the chunks
          if (createdData.length > 0) {
            processChunks();
          }
        });
    }
  };

  return (
    <div>
      <div className="d-flex gap-2 mb-3 align-items-center">
        <div className="form-icon-container w-30">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('enter_product')}
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e?.target?.value)}
              className="form-icon-input"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('enter_product')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>
        <div className="react-select-container">
          <Form.Floating>
            <Form.Select
              value={selectedMeasureUnitId}
              onChange={(e: any) => {
                const selectedOption = measureunitOption.find(
                  (option: any) => option.value === e.target.value
                );

                if (selectedOption) {
                  setSelectedMeasureUnitId(selectedOption?.value);
                }
              }}
            >
              <option className="d-none" value=""></option>
              {measureunitOption?.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <label htmlFor="floatingInputCustom">{t('measure_unit')}</label>
            {selectedMeasureUnitId !== '' && (
              <Button
                type="button"
                variant="link"
                style={{
                  position: 'absolute',
                  right: '5%',
                  top: '15%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setSelectedMeasureUnitId('');
                }}
              >
                <FeatherIcon icon="delete" size={16} />
              </Button>
            )}
          </Form.Floating>
        </div>

        <Form.Group className="d-flex gap-2 align-items-center">
          <Form.Check
            checked={positiveValue}
            onChange={e => {
              setPositiveValue(e.target.checked);
              if (e.target.checked) {
                setNegativeValue(false); // Deselect negativeValue
              }
            }}
            label={t('positive_products')}
            className="cursor-pointer"
          />
          <Form.Check
            checked={negativeValue}
            onChange={e => {
              setNegativeValue(e.target.checked);
              if (e.target.checked) {
                setPositiveValue(false); // Deselect positiveValue
              }
            }}
            label={t('minus_products')}
            className="cursor-pointer"
          />
        </Form.Group>

        <Button
          type="button"
          style={{ height: '48px', marginLeft: 'auto' }}
          variant="primary"
          loadingPosition="start"
          loading={allLoading}
          onClick={SubmitAll}
        >
          {t('add_all')}
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="position-relative">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loadingData}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {submitLoading && (
            <div className="loading-table">
              <span>
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: '#8200BF', width: '3rem', height: '3rem' }}
                ></Spinner>
              </span>
            </div>
          )}
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default WireOffNotInLists;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import usePageSearchParams from 'utils/usePageSearchParams';

import paymentMethodService from 'service/paymentMethod';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import customerEntityService from 'service/charterer';

interface AddPurchaseToChildProps {
  setOpenChild?: any;
  refetch?: any;
}

const AddPurchaseToChild = ({
  setOpenChild,
  refetch
}: AddPurchaseToChildProps) => {
  const { t, i18n } = useTranslation();

  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);

  const schema = yup.object().shape({
    remainder: yup.string(),
    payment_type: yup.string(),
    debit: yup.string().required(t('required_field')),
    note: yup.string()
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();

  const debitor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');
  const creditor_id = searchParams.get('creditor_id');

  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [selctedPaymentsId, setSelectedPaymentsId] = useState<any>();

  //GET Wallet to options
  useQuery(['GET_METHOD'], async () => {
    await paymentMethodService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_payment_methods?.map(
          (option: any) => ({
            value: option?.paymentMethod?.id,
            label: option?.paymentMethod?.name?.[i18n?.language],
            payment_method_id: option?.payment_method_id,
            payment_type_id: option?.paymentMethod?.payment_type_id
          })
        );
        setPaymentMethod(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (+data?.balance === 0 || +data?.debit <= 0) {
      setLoading(false);
      dispatch(
        showAlert({
          title: t('not_enough_product')
        })
      );
    }

    const createdData = {
      cashbox_id: cashboxId,
      amount: +data?.debit || 0,
      currency_id: currency_id,
      payment_method_id: selctedPaymentsId?.payment_method_id,
      payment_type_id: selctedPaymentsId?.payment_type_id,
      debitor_id: debitor_id,
      creditor_id: creditor_id,
      note: data?.note ?? '',
      is_auto: false
    };

    customerEntityService
      .createCustomerSettelment(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: t('successfully_added'),
              type: 'success'
            })
          );
          refetch();
          setLoading(false);
          setOpenChild(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="debit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('enter_amount')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.debit}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('enter_amount')}
                  </label>
                  {errors.debit && (
                    <Form.Control.Feedback type="invalid">
                      {errors.debit.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="payment_type"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = paymentMethod.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setSelectedPaymentsId({
                            payment_method_id:
                              selectedOption?.payment_method_id,
                            payment_type_id: selectedOption?.payment_type_id
                          });
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.payment_type}
                    >
                      <option className="d-none" value=""></option>
                      {paymentMethod?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('payment_type')}
                    </label>
                    {errors?.payment_type?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.payment_type?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('note')}
                type="text"
                placeholder={t('note')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('note')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default AddPurchaseToChild;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from 'react-to-print';
import { useState, useEffect, useRef } from 'react';
import { utils, writeFile } from 'xlsx';
import { useTranslation } from 'react-i18next';

import ComponentCheckToPrint from './ComponentCheckToPrint';
import Button from 'components/base/Button';
import cashboxService from 'service/cashbox';

interface PrintCheckProps {
  selectedCheckId?: any;
}

import './print.scss';

const PrintCheck = ({ selectedCheckId }: PrintCheckProps) => {
  const { t, i18n } = useTranslation();
  const legalEntityInfo = useSelector(
    (state: any) => state?.auth?.legal_entity
  );

  const componentRef = useRef<any>(null);
  const [pageStyle, setPageStyle] = useState<string>('');

  console.log('legalEntityInfo', legalEntityInfo);
  //Get Product Search
  const { data } = useQuery(
    ['GET_CHECK_PRINT'],
    async () => {
      if (selectedCheckId) {
        const res = await cashboxService
          .getReceiptById(selectedCheckId)
          .then((res: any) => {
            return res;
          });
        return res;
      }
    },
    {
      enabled: !!selectedCheckId
    }
  );

  console.log('data', data);

  const exportToExcel = () => {
    // Ensure that 'data' and its properties exist before proceeding
    if (!data || !data.receipt || !data.sales) {
      console.error('Data is missing or incomplete');
      return;
    }

    const exportData = [
      [
        'Кассир',
        data?.receipt?.employee?.first_name +
          ' ' +
          data?.receipt?.employee?.last_name
      ],
      ['Чек №', data.receipt.receipt_number],
      ['Дата №', new Date(data.receipt.date_create).toLocaleString()],
      [],
      ['Наименование', 'Штрих-код', 'Количество', 'Цена', 'Всего'],
      ...data.sales.map((el: any, index: number) => {
        // Check for the specific measure_unit_id
        let quantityDisplay;
        if (
          el?.product?.measure_unit_id ===
          '443bfff1-61e0-4057-8583-d040dc5a0454'
        ) {
          const packageQuantity = el?.product?.package_quantity || 1;
          const quantity = String(el?.quantity ?? 0) || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (quantity?.includes('.')) {
            [numerator, denominator] = quantity.split('.').map(Number);
            integerPart = numerator; // Whole number part
            denominator = +`0.${denominator}`; // Remainder of the division
          } else {
            integerPart = +quantity;
          }
          quantityDisplay = quantity?.includes('.')
            ? `${integerPart}/${+denominator * packageQuantity}`
            : integerPart;
        } else {
          quantityDisplay = el?.quantity || '';
        }

        return [
          `${index + 1}. ${el?.product?.name}`,
          el?.product?.barcode || '',
          quantityDisplay, // Updated quantity display logic
          el?.payment_amount || '',
          el?.payment_amount_total || ''
        ];
      }),
      [],
      ['Сумма продаж', data.receipt.amount || '']
    ];

    const worksheet = utils.aoa_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Receipt');

    const today = new Date();
    const formattedDate = today
      .toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
      .replace(/\//g, '_');

    const fileName = `Чек_${formattedDate}.xlsx`;

    writeFile(workbook, fileName);
  };

  useEffect(() => {
    if (componentRef.current) {
      setPageStyle(`
        @page {
          size: 80mm auto;
          margin: 0;
          padding: 0;
        };

       @media print {
          body {
            margin: 0;
            padding: 0;
          }
          .print-container {
            margin: 0;
            padding: 0;
            width: 80mm;
            height: auto;
            break-inside: avoid; /* Prevents breaking inside the component */
          }
        }
      `);
    }
  }, [componentRef]);

  const debtCost =
    data?.debit_credit?.amount_debit && data?.debit_credit?.amount_credit
      ? data?.debit_credit?.amount_debit - data?.debit_credit?.amount_credit
      : 0;

  const totalAmountReturn = data?.sales?.reduce(
    (sum: any, item: any) => sum + (item.amount_returned || 0),
    0
  );

  return (
    <div className="check-container">
      <div className="legal-entity-info">
        <p className="dublicat">{t('duplicate')}</p>
        <p className="title">{legalEntityInfo?.name}</p>
        <p className="phone">
          {t('phone_number')}: {legalEntityInfo?.phone_number}
        </p>
        <p className="address">
          {t('address')}: {legalEntityInfo?.address}
        </p>
      </div>

      <div className="receipt-info">
        <ul className="receipt-titles-header">
          <li>{t('cashier')}</li>
          <li>{t('check_number')}</li>
          <li>{t('payment_date')}</li>
        </ul>

        <ul className="receipt-titles">
          <li>
            {data?.receipt?.employee?.first_name +
              ' ' +
              data?.receipt?.employee?.last_name}
          </li>
          <li>{data?.receipt?.receipt_number}</li>
          <li>
            {new Date(data?.receipt?.date_create)
              .toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })
              .replace(/\//g, '-')
              .replace(',', '')}
          </li>
        </ul>
      </div>

      <div className="sales-info">
        <div className="sales-header">
          <p className="header">№</p>
          <p className="header">{t('product')}</p>
          <p
            className="header"
            style={
              i18n?.language === 'ru'
                ? { marginLeft: '120px' }
                : { marginLeft: '100px' }
            }
          >
            {t('qnt')}
          </p>
          <p
            className="header"
            style={i18n?.language === 'ru' ? { marginLeft: '45px' } : {}}
          >
            {t('to_pay')}
          </p>
        </div>

        {data?.sales?.map((el: any, index: any) => {
          const packageQuantity = el?.product?.package_quantity || 1;
          const quantity = String(el?.quantity ?? 0) || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (quantity?.includes('.')) {
            [numerator, denominator] = quantity.split('.').map(Number);
            integerPart = numerator; // Whole number part
            denominator = +`0.${denominator}`; // Remainder of the division
          } else {
            integerPart = +quantity;
          }
          const quantityDisplay = quantity?.includes('.')
            ? `${integerPart}/${+denominator * packageQuantity}`
            : integerPart;

          return (
            <div key={el?.id} className="sales-box">
              <div className="sales-names">
                <p className="order">{index + 1}</p>
                <p className="titles">{el?.product?.name}</p>
              </div>

              <div className="sales-costs-box">
                <div className="sales-costs">
                  {/* prettier-ignore */}
                  <p className="calculation">
                    {el?.product?.measure_unit_id ===
                    '443bfff1-61e0-4057-8583-d040dc5a0454'
                      ? quantityDisplay
                      : el?.quantity
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.quantity)
                            .replace(/,/g, ' ')
                        : 0}{' '}
                    X{' '}
                    {el?.payment_amount
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          useGrouping: true
                        })
                          .format(el?.payment_amount)
                          .replace(/,/g, ' ')
                      : 0}
                  </p>
                  <p className="total">
                    {el?.payment_amount_total
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          useGrouping: true
                        })
                          .format(el?.payment_amount_total)
                          .replace(/,/g, ' ')
                      : 0}
                  </p>
                </div>
                {el?.amount_returned && (
                  <div className="sales-costs-returned">
                    <p className="calculation">
                      {el?.quantity_returned
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.quantity_returned)
                            .replace(/,/g, ' ')
                        : 0}{' '}
                      X{' '}
                      {el?.payment_amount
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.payment_amount)
                            .replace(/,/g, ' ')
                        : 0}
                    </p>
                    <p className="total">
                      {el?.amount_returned
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            useGrouping: true
                          })
                            .format(el?.amount_returned)
                            .replace(/,/g, ' ')
                        : 0}
                    </p>
                  </div>
                )}
                {/* <div className="sales-costs-vat">
                  <p className="vat">
                    в.т.ч. НДС 12% {el?.vat_value?.toFixed(2) ?? 0}
                  </p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>

      <div className="sales-total">
        <ul>
          <li>{t('sales_amount')}</li>
          <li>{t('discount')}</li>
        </ul>
        <ul>
          <li>
            {data?.receipt?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
                })
                  .format(
                    data?.receipt?.amount +
                      (data?.receipt?.discount_amount ?? 0)
                  )
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.receipt?.discount_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
                })
                  .format(data?.receipt?.discount_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
        </ul>
      </div>

      <div className="to-pay">
        <p className="pay-title">{t('to_pay')}</p>
        <p className="pay-sum">
          {data?.receipt?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
              })
                .format(data?.receipt?.amount)
                .replace(/,/g, ' ')
            : 0}
        </p>
      </div>

      <div className="is-paied">
        <p className="is-paied-title">{t('paid')}</p>
        <p className="is-paied-sum">
          {data?.receipt?.payed_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
              })
                .format(data?.receipt?.payed_amount)
                .replace(/,/g, ' ')
            : 0}
        </p>
      </div>

      <div className="is-paied">
        <p className="is-paied-title">{t('return')}</p>
        <p className="is-paied-sum">
          {totalAmountReturn
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
              })
                .format(totalAmountReturn)
                .replace(/,/g, ' ')
            : 0}
        </p>
      </div>

      {data?.sale_transaction &&
        data?.sale_transaction
          ?.filter((el: any) => el?.amount_in)
          ?.map((el: any) => (
            <div key={el?.id} className="sale-transaction">
              <p className="title">
                {el?.payment_method?.name?.[i18n?.language]}
              </p>
              <p className="sum">
                {el?.amount_in
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                      useGrouping: true
                    })
                      .format(el?.amount_in)
                      .replace(/,/g, ' ')
                  : 0}{' '}
                {el?.currency?.name?.[i18n?.language]}
              </p>
            </div>
          ))}

      <div className="extra">
        <ul>
          <li>{t('vat')}</li>
          <li>{t('bonuses')}</li>
        </ul>
        <ul>
          <li>0</li>
          <li>0</li>
        </ul>
      </div>

      <div className="is-debt">
        <p className="is-debt-title">{t('amount_due')}</p>
        <p className="is-debt-sum">{debtCost}</p>
      </div>

      <div className="is-debt">
        <p className="is-debt-title">{t('contact')}</p>
        <p className="is-debt-sum">
          {data?.receipt?.user
            ? data?.receipt?.user?.first_name +
              ' ' +
              data?.receipt?.user?.last_name
            : ''}
        </p>
      </div>

      <div className="check-print">
        <Button
          style={{ height: '48px', width: '100%' }}
          variant="outline-primary"
          startIcon={<FeatherIcon icon="upload-cloud" size={20} />}
          onClick={exportToExcel}
        >
          {t('export')}
        </Button>

        <ReactToPrint
          trigger={() => (
            <Button
              variant="primary"
              style={{ width: '100%', height: '48px' }}
              startIcon={<FeatherIcon icon="printer" size={22} />}
            >
              {t('print')}
            </Button>
          )}
          content={() => componentRef?.current}
          pageStyle={pageStyle}
        />
      </div>

      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="p-0 m-0">
          <ComponentCheckToPrint data={data} />
        </div>
      </div>
    </div>
  );
};

export default PrintCheck;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Offcanvas, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';
import classNames from 'classnames';

import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import legalEntitySettingsService from 'service/legalEntitySettings';
import conversionService from 'service/conversion';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CreateConversion from './CreateConversion';

export const conversionBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'currency_conversion',
    url: '/currency-conversion/exchange-rate'
  },
  {
    label: 'exchange',
    active: true
  }
];

export type ConversionType = {
  base: string;
  main_currency: string;
  convertible: string;
  units: string;
  create_date: string;
  guid: string;
};

const Conversion = () => {
  const { t, i18n } = useTranslation();
  console.log('test');

  const [openConversion, setOpenConversion] = useState(false);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);

  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const adjustedDateFrom = dateFrom || todayStart;
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? todayEnd + 86400000
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get Legal entity
  const { data, refetch } = useQuery(
    ['GET_CONVERSION', adjustedDateFrom, adjustedDateTo],
    () => {
      const res = conversionService
        .getList({
          limit: 100,
          offset: 0,
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.currency_conversions);
      return res;
    },
    { enabled: !!adjustedDateFrom || !!adjustedDateTo }
  );

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const conversionData: ConversionType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          base: items?.amount_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_in)
                .replace(/,/g, ' ')
            : '0',
          main_currency: items?.from_currency?.name?.[i18n?.language],
          convertible: items?.amount_out
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount_out)
                .replace(/,/g, ' ')
            : '0',
          units: items?.to_currency?.name?.[i18n?.language],
          /* prettier-ignore */
          cashier: items?.employee ? items?.employee?.first_name + ' ' + items?.employee?.last_name : "",
          /* prettier-ignore */
          contact: items?.user
            ? items?.user?.first_name + ' ' + items?.user?.last_name
            : '',
          /* prettier-ignore */
          create_date: formatDateToTimeZone(items?.date_create, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings, t, i18n?.language]);

  const conversionTableColumns: ColumnDef<ConversionType>[] = [
    {
      accessorKey: 'base',
      header: t('received'),
      cell: rowData => {
        const { base, main_currency } = rowData.row.original;
        return (
          <p className="m-0 p-0">
            {base} ({main_currency})
          </p>
        );
      },

      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'convertible',
      header: t('sent'),
      cell: rowData => {
        const { convertible, units } = rowData.row.original;
        return (
          <p className="m-0 p-0">
            {convertible} ({units})
          </p>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'contact',
      header: t('contacts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cashier',
      header: t('cashier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'create_date',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  const table = useAdvanceTable({
    data: conversionData,
    columns: conversionTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (!dateFrom) {
      setDateFrom(todayStart);
    }
    if (!dateTo) {
      setDateTo(todayEnd);
    }
  }, [dateFrom, dateTo, setDateFrom, setDateTo]);

  return (
    <div>
      <PageBreadcrumb items={conversionBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('exchange')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                <Form.Group className="md-4">
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: getFlatpickrLocale(),
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'M j, Y',
                        disableMobile: true
                      }}
                      value={dateFrom}
                      onChange={handleStartDateChange}
                      placeholder={t('date_from')}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t('date_from')}
                    </label>
                  </Form.Floating>
                </Form.Group>

                <Form.Group className="md-4">
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: getFlatpickrLocale(),
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'M j, Y',
                        disableMobile: true
                      }}
                      value={dateTo}
                      onChange={handleEndDateChange}
                      placeholder={t('date_to')}
                    />
                    <label htmlFor="floatingInputCustom">{t('date_to')}</label>
                  </Form.Floating>
                </Form.Group>
              </div>

              <div className="d-flex justify-content-between gap-2">
                <Button
                  variant="primary"
                  onClick={() => setOpenConversion(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('exchange')}
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Offcanvas
            show={openConversion}
            onHide={() => {
              setOpenConversion(false);
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateConversion
                setOpenConversion={setOpenConversion}
                refetch={refetch}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Conversion;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';

interface AddQuantityToItemsPropds {
  setOpenAddProduct: (show: boolean) => void;
  addedProduct?: any;
  selectedData?: any;
  setSelectedData?: any;
  inputRef?: any;
}

const AddQuantityToItems = ({
  setOpenAddProduct,
  addedProduct,
  selectedData,
  setSelectedData,
  inputRef
}: AddQuantityToItemsPropds) => {
  const { t } = useTranslation();

  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const inputRefQuantity = useRef<HTMLInputElement | any>();

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    const createdData = {
      ...addedProduct,
      quantity: +data?.quantity || 0
    };
    setSelectedData([...selectedData, createdData]);
    dispatch(
      showAlert({
        title: t('product_successfully_added'),
        type: 'success'
      })
    );
    setLoading(false);
    setOpenAddProduct(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  useEffect(() => {
    if (inputRefQuantity.current) {
      inputRefQuantity.current.focus();
    }
  }, [inputRefQuantity.current]);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <Controller
              name={`quantity`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRefQuantity}
                    type="text"
                    placeholder={t('quantity')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.quantity}
                  />
                  <label htmlFor="floatingInputCustom">{t('quantity')}</label>
                  {errors.quantity && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default AddQuantityToItems;

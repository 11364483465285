/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const conversionService = {
  getList: (params: any) => request.get('/currency-conversion', { params }),
  getRateById: (id: any) => request.get(`/currency-conversion/${id}`),
  createRate: (data: any) => request.post('/currency-conversion', data),
  deleteRate: (id: any) => request.delete(`/currency-conversion/${id}`),
  updateRate: (id: any, data: any) =>
    request.put(`/currency-conversion/${id}`, data)
};

export default conversionService;

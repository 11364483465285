/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import { ComingResult } from 'data/movement';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { showAlert } from 'store/alert/alert.thunk';
import warehouseService from 'service/warehouse';
import movingInService from 'service/movingIn';
import movingItemService from 'service/movingItem';
import './styles.scss';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import movingService from 'service/movement';
import SupperModal from 'components/modals/SupperModal';

const ComingResultMovements = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const movingId = pathname.split('/').pop();
  const dispatch: Dispatch<any> = useDispatch();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const navigate = useNavigate();

  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);
  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>('');

  const [updatedMovingItems, setUpdatedMovingItems] = useState<any>([]);
  const [getRowGuid, setGetRowGuid] = useState('');

  console.log('confirmToDelete', confirmToDelete);

  //GET Document status to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option?.id,
          label: option?.name
        }));
        setWarehouseOption(options);
      });
  });

  //Fetch Users By ID
  const { refetch: refetchIn } = useQuery(
    ['GET_MOVING_IN_CAN_UPDATE', movingId],
    async () => {
      if (movingId) {
        const res = await movingInService
          .getList({
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.movings;
          });

        if (res) {
          const updatedRes = res?.find((el: any) => el?.id === movingId);
          setCanUpdate(updatedRes?.can_update ?? false);
        }
      }
    },
    { enabled: !!movingId }
  );

  const { data, refetch } = useQuery(
    ['GET_MOVING_ITEM_IN', movingId],
    async () => {
      if (movingId) {
        const res = await movingItemService
          .getMovingItemInById(movingId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.moving_items;
          });
        return res;
      }
    },
    { enabled: !!movingId }
  );

  const comingResultData: ComingResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          can_update: items?.can_update ?? false,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product?.name,
          product_id: items?.product_id,
          product_parent_id: items?.product_parent_id,
          barcode: items?.product?.barcode,
          sent: items?.quantity_send ?? 0,
          accept: items?.quantity_fact
            ? items?.quantity_fact
            : items?.quantity_send ?? 0,
          discrepancy: Math.max(
            (items?.quantity_send ?? 0) -
              (items?.quantity_fact
                ? items?.quantity_fact
                : items?.quantity_send ?? 0)
          ),
          units: items?.nomenclature?.measure_unit?.name?.[i18n?.language],
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity,
          status: items?.status?.name?.[i18n?.language],
          cost: items?.price_in ?? 0,
          remainder: items?.total_quantity ?? 0,
          currency_id: items?.currency_id,
          price: items?.price ? +items?.price : 0,
          price_in: items?.price_in ? +items?.price_in : 0,
          price_wholesale: items?.price_whosale ? +items?.price_whosale : 0,

          /* prettier-ignore */
          history:
            items?.price_in && items?.quantity ? +items.price_in * +items.quantity: 0
        };
      }) ?? [];

    if (resultData) {
      setUpdatedMovingItems(resultData);
    }

    return resultData;
  }, [data, t, i18n?.language]);

  const item = useMemo(() => {
    return updatedMovingItems.find((item: any) => item.guid === getRowGuid);
  }, [updatedMovingItems, getRowGuid]);

  const handleAcceptProduct = (id: any) => {
    if (id) {
      movingItemService
        .updateMovingItemIn(id, {
          status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
        })
        .finally(() => {
          refetch();
        });
    }
  };

  const comingResultTableColumns: ColumnDef<ComingResult>[] = [
    {
      accessorKey: 'product_name',
      header: t('product_names'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sent',
      header: t('short_quantity_sent'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accept',
      header: t('short_qunatity_accept'),
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const updatedItems = updatedMovingItems.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });

          setUpdatedMovingItems(updatedItems);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="accept"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'discrepancy',
      header: t('short_no_expoenses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          Завершен: { bg: '#BBFBD0', text: '#119C2B' },
          Отправлен: { bg: '#fef3c7', text: '#92400e' },
          Bajarildi: { bg: '#BBFBD0', text: '#119C2B' },
          "Jo'natilgan": { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'accept_remainder',
      header: t('accept_remainder'),
      cell: rowData => {
        setGetRowGuid(rowData.row.original.guid);
        const item = updatedMovingItems.find(
          (item: any) => item.guid === rowData.row.original.guid
        );

        return (
          <div style={{ display: item?.can_update ? 'block' : 'none' }}>
            <Button
              className="p-2"
              variant="primary"
              onClick={() => handleAcceptProduct(rowData?.row?.original?.guid)}
            >
              {t('accept_remainder')}
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: {
            display: item?.can_update ? 'block' : 'none'
          }
        },
        cellProps: {
          className: 'text-900',
          style: {
            display: item?.can_update ? 'block' : 'none'
          }
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: updatedMovingItems,
    columns: comingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const movingBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'movements',
      url: '/movements/outgoing'
    },
    {
      label: 'incoming',
      url: '/movements/coming'
    },
    {
      label: 'application_number',
      active: true
    }
  ];

  const sum = comingResultData.reduce((acc: any, item: any) => {
    const allCost = item?.discrepancy === undefined ? 0 : +item?.discrepancy;
    return acc + allCost;
  }, 0);

  const handleSave = () => {
    console.log('test');
    setSaveLoading(true);

    if (warehouseId === '') {
      dispatch(
        showAlert({
          title: t('warehouse_must_selected')
        })
      );
      setOpenSupperModal(false);
      setConfirmToDelete(false);
    }
    if (cashboxId === '') {
      dispatch(
        showAlert({
          title: t('shift_not_open')
        })
      );
      setOpenSupperModal(false);
      setConfirmToDelete(false);
    }

    const createData = updatedMovingItems?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price ? +el?.price : 0,
      price_in: el?.price_in ? +el?.price_in : 0,
      price_wholesale: el?.price_wholesale ? +el?.price_wholesale : 0,
      quantity_send: el?.sent ? +el?.sent : 0,
      quantity_fact: el?.accept ? +el?.accept : 0,
      id: el?.guid,
      status_id: '00525476-8e64-40bc-9dd2-083583c95b30' // static new status id
    }));

    const updateData = {
      moving_id: movingId,
      moving_items: createData
    };
    if (warehouseId !== '' && cashboxId !== '') {
      movingItemService.createMovingItem(updateData).then((createres: any) => {
        if (createres) {
          movingService.getMovingById(movingId).then((res: any) => {
            if (res) {
              movingInService
                ?.updateMovingIn(movingId, {
                  ...res,
                  warehouse_id: warehouseId,
                  cashbox_id: cashboxId,
                  status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
                })
                .then((inresponse: any) => {
                  if (inresponse) {
                    dispatch(
                      showAlert({
                        title: t('successfully_accepted'),
                        type: 'success'
                      })
                    );
                    refetch();
                    refetchIn();
                    navigate('/movements/coming');
                  }
                });
            }
          });
        }
      });
    }
  };

  const handleFinished = () => {
    setFinishLoading(true);

    if (cashboxId === '') {
      dispatch(
        showAlert({
          title: t('shift_not_open')
        })
      );
    }

    const createData = updatedMovingItems?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      product_parent_id: el?.product_parent_id,
      price: el?.price ? +el?.price : 0,
      price_in: el?.price_in ? +el?.price_in : 0,
      price_wholesale: el?.price_wholesale ? +el?.price_wholesale : 0,
      price_difference: 0,
      quantity_send: el?.amount ? +el?.amount : 0,
      quantity_fact: el?.quantity_fact ? +el?.quantity_fact : 0,
      id: el?.guid,
      status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d' // static new status id
    }));

    const updateData = {
      moving_id: movingId,
      moving_items: createData
    };
    if (cashboxId !== '') {
      movingService.getMovingById(movingId).then((res: any) => {
        if (res) {
          movingInService
            .updateMovingIn(movingId, {
              ...res,
              cashbox_id: cashboxId,
              status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
            })
            .then((outresponse: any) => {
              if (outresponse) {
                movingItemService
                  .createMovingItem(updateData)
                  .then((createres: any) => {
                    if (createres) {
                      setFinishLoading(false);
                      navigate('/movements/coming');
                    }
                  });
              }
            });
        }
      });
    }
  };

  useEffect(() => {
    if (confirmToDelete) {
      handleSave();
    }
  }, [confirmToDelete]);

  return (
    <div>
      <PageBreadcrumb items={movingBreadcrumbItems} />

      <div>
        <h2 className="mb-5">{t('application_number')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <SupperModal
          show={openSupperModal}
          setShow={setOpenSupperModal}
          setConfirm={setConfirmToDelete}
          title={t('movements')}
          bodyText={t('want_complete_moves')}
        />

        <div className="footer-mov-com">
          <div className="main-btn-group">
            <div className="react-select-container">
              <Form.Floating>
                <Form.Select
                  onChange={(e: any) => {
                    const selectedOption = warehouseOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setWarehouseId(selectedOption?.value);
                    }
                  }}
                >
                  <option className="d-none" value=""></option>
                  {warehouseOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('sender')}</label>
              </Form.Floating>
            </div>
            <Form.Group className="main-check">
              <Form.Check
                type="switch"
                label="Обновить цену продажи"
                className="cursor-pointer"
              />
            </Form.Group>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">{t('total_amount')}</p>
            <span>{sum ?? 0}</span>
          </div>

          <div className="main-btn-group">
            <Button
              onClick={() => setOpenSupperModal(true)}
              disabled={!canUpdate}
              variant="primary"
              loading={saveLoading}
            >
              {t('accept')}
            </Button>
            <Button
              onClick={() => handleFinished()}
              disabled={!canUpdate}
              variant="danger"
              loading={finishLoading}
            >
              {t('reject')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingResultMovements;

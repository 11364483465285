/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import ProductFilter from './filter/ProductFilter';
import ProductTable from './table/ProductTable';

import './styles.scss';

export const productBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'reports',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'sale',
    url: '/reports/sale/payments'
  },

  {
    label: 'sales_product',
    active: true
  }
];

const ByProduct = () => {
  const { t, i18n } = useTranslation();
  const currencyName = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency?.name?.[i18n?.language]
  );

  const [generateTableData, setGeneratedTableData] = useState([]);
  const [savedValues, setSavedValues] = useState([]);
  const [getName, setGetName] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [getFilterData, setGetFilterData] = useState({});
  const [selectedCurrencyName, setCurrencyName] = useState('');

  const sumOfAllCost = generateTableData.reduce((acc: any, item: any) => {
    const allCost =
      item?.total_payment_price === undefined
        ? 0
        : +String(item.total_payment_price || '')
            ?.split(' ')
            ?.join('');
    return acc + allCost;
  }, 0);

  const formatPrice = (price: any) => {
    const priceNumber = Number(price);
    if (isNaN(priceNumber)) {
      return { main: '0', decimals: '00' };
    }
    const priceParts = Number.isInteger(priceNumber)
      ? [priceNumber?.toString(), '00']
      : priceNumber?.toFixed(4).split('.');
    return {
      main: priceParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      decimals: priceParts[1]
    };
  };

  const { main, decimals } = formatPrice(sumOfAllCost);

  return (
    <div>
      <PageBreadcrumb items={productBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('sales_product')}</h2>

        <ProductFilter
          setGeneratedTableData={setGeneratedTableData}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          setGetName={setGetName}
          setGetBarcode={setGetBarcode}
          getName={getName}
          getBarcode={getBarcode}
          setGetFilterData={setGetFilterData}
          setCurrencyName={setCurrencyName}
          getFilterData={getFilterData}
        />
        <ProductTable
          generateTableData={generateTableData}
          setGeneratedTableData={setGeneratedTableData}
          getName={getName}
          getBarcode={getBarcode}
          getFilterData={getFilterData}
        />

        <div className="footer-bypro">
          <div className="box">
            <p className="mb-0" style={{ fontWeight: '800' }}>
              {t('total_amount')}: (
              {selectedCurrencyName !== ''
                ? selectedCurrencyName
                : currencyName}
              )
            </p>
            <span style={{ fontWeight: '800' }}>
              {main}.{decimals}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ByProduct;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo, useRef } from 'react';

import customerEntityService from 'service/charterer';
import { pageToOffset } from 'utils/pageToOffset';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import legalEntitySettingsService from 'service/legalEntitySettings';

export type SettelementData = {
  cashier: string;
  currency: string;
  input_balance: string;
  amounts: string;
  output_balance: string;
  status: string;
  transaction_type: string;
  can_update: boolean;
  bg_color: string;
  text_color: string;
  movedToTop: string;
  isArrowRight: boolean;
  guid: string;
};

interface CompareReturnTableProps {
  selecetedTargetId?: string;
  dateFrom?: any;
  adjustedDateTo?: any;
  openSelected?: any;
}

const CompareReturnTable = ({
  selecetedTargetId,
  dateFrom,
  adjustedDateTo,
  openSelected
}: CompareReturnTableProps) => {
  const { t, i18n } = useTranslation();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [pageClicked, setPageClicked] = useState(false);

  const hasSetDefaultPage = useRef(false);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  const { data, isFetching, isLoading } = useQuery(
    ['GET_HISTORY_SETTLEMENT_ID', currentPage, selecetedTargetId, pageClicked],
    () => {
      const res = customerEntityService
        .getListHistorySettlement({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          sort: 1,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          law_subject_type: 'INDIVIDUAL',
          target_id: !pageClicked ? selecetedTargetId : undefined
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!selecetedTargetId || !!currentPage
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));

      if (!hasSetDefaultPage.current && data?.target_offset !== undefined) {
        const defaultPage = Math.floor(data.target_offset / 10) + 1;
        setCurrentPage(defaultPage || 0);
        hasSetDefaultPage.current = true; // Ensure this is only executed once
      }
    }
  }, [data]);

  useEffect(() => {
    if (openSelected) {
      setCurrentPage(1); // Reset to initial page
      hasSetDefaultPage.current = false; // Allow default page logic to run again
    }
  }, [openSelected]);

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';
    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resultData =
      data?.debit_credit_settlements?.map((items: any) => {
        return {
          cashier:
            items?.employee?.first_name + ' ' + items?.employee?.last_name,
          input_balance: items?.balance_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_in)
                .replace(/,/g, ' ')
            : 0,
          output_balance: items?.balance_out
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_out)
                .replace(/,/g, ' ')
            : 0,
          amount: items?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount)
                .replace(/,/g, ' ')
            : 0,
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          status: items?.status?.name?.[i18n?.language],
          can_update: items?.can_update === true ? false : true,
          currency: items?.currency?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          movedToTop: items?.id === selecetedTargetId,
          guid: items?.id,
          transaction_type: items?.transaction_type
        };
      }) ?? [];

    setSettlementData(resultData);
  }, [data, t, i18n?.language, dataSettings, selecetedTargetId]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      accessorKey: 'cashier',
      header: t('cashier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'input_balance',
      header: t('input_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'amount',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'output_balance',
      header: t('output_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'transaction_type',
      header: t('transaction_type'),
      cell: rowData => {
        /* prettier-ignore */
        return (
          <div>
            {rowData?.row?.original?.transaction_type === 'DEBIT'
              ? t('DEBIT')
              : rowData?.row?.original?.transaction_type === 'CREDIT'
                ? t('CREDIT')
                : rowData?.row?.original?.transaction_type === 'RETURN'
                  ? t('RETURN')
                  : t('RECALCULATION')}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status, bg_color, text_color } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          новый: { bg: '#1d4289', text: '#ffffff' },
          'Завершен с расхождением': { bg: '#fef3c7', text: '#92400e' },
          Yangi: { bg: '#fef3c7', text: '#92400e' },
          'Qisman tugallangan': { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
          bg_color || defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor =
          text_color || defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settlementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={isLoading || isFetching}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(event, page) => {
              if (page) {
                setPageClicked(true);
              }
            }}
          />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default CompareReturnTable;

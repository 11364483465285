/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import usePageSearchParams from 'utils/usePageSearchParams';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import walletService from 'service/wallet';
import providerService from 'service/provider';

interface AddSettelementProps {
  setOpenSettelement: (show: boolean) => void;
  refetch?: any;
}

const formatNumber = (value: string) => {
  return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const removeSpaces = (value: string) => {
  return value?.replace(/\s/g, '');
};

const AddSettelement = ({
  setOpenSettelement,
  refetch
}: AddSettelementProps) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    wallet: yup.string().required(t('required_field')),
    balance: yup.string(),
    note: yup.string(),
    debit: yup.string().required(t('required_field'))
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const creditor_id = pathname.split('/').pop();
  const debitor_id = searchParams.get('debitor_id');
  const currency_id = searchParams.get('currency_id');

  const [loading, setLoading] = useState(false);
  const [walletOption, setWalletOption] = useState<any>([
    { label: '', value: '', total_balance: '' }
  ]);
  const [disabledFields, setDisabledFields] = useState(false);

  //GET Wallet to options
  useQuery(
    ['GET_WALLET', currency_id],
    async () => {
      if (currency_id !== '') {
        await walletService
          .getList({
            offset: 0,
            limit: 20,
            currency_id: currency_id
          })
          .then((res: any) => {
            const options = res?.wallets?.map((option: any) => ({
              value: option.id,
              label: option.name,
              total_balance: option?.total_balance
            }));
            setWalletOption(options);
          });
      }
    },
    { enabled: currency_id !== '' }
  );

  const onSubmit: SubmitHandler<FieldValues> = data => {
    createData(data);
  };

  const createData = (data: any) => {
    const balance = +removeSpaces(data?.balance);
    const debit = +removeSpaces(data?.debit);
    if (balance === 0 || balance < debit) {
      setLoading(false);
      dispatch(
        showAlert({
          title: t('not_enough_product')
        })
      );
      return;
    }

    if (!cashboxId) {
      dispatch(showAlert({ title: t('shift_not_open') }));
      return;
    }

    const createdData = {
      currency_id: currency_id,
      cashbox_id: cashboxId,
      amount: debit,
      creditor_id: creditor_id,
      debitor_id: debitor_id,
      note: data?.note,
      wallet_id: data?.wallet
    };

    if (balance > 0 && balance >= debit) {
      setLoading(true);

      providerService
        .createProviderSettlement(createdData)
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: `${t('mutual_settlement')} ${t('successfully_added')}`,
                type: 'success'
              })
            );
            refetch();
            setOpenSettelement(false);
            setLoading(false);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (disabledFields && inputRef.current) {
      inputRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current, disabledFields]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="wallet"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = walletOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue(
                            'balance',
                            selectedOption.total_balance
                              ? new Intl.NumberFormat('en-US', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 4,
                                  useGrouping: true
                                })
                                  .format(selectedOption.total_balance)
                                  .replace(/,/g, ' ')
                              : '0'
                          );

                          setDisabledFields(true);
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.wallet}
                    >
                      <option className="d-none" value=""></option>
                      {walletOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('wallet')}</label>
                    {errors?.wallet?.message && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {errors?.wallet?.message}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                disabled={disabledFields}
                {...register('balance')}
                type="text"
                placeholder={t('balance')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('balance')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="debit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={disabledFields === true ? inputRef : undefined}
                    type="text"
                    placeholder={t('debit')}
                    onFocus={e => e.target.select()}
                    onChange={e => {
                      const rawValue = removeSpaces(e.target.value);
                      const formattedValue = formatNumber(rawValue);
                      field.onChange(formattedValue);
                    }}
                    value={field.value}
                    isInvalid={!!errors.debit}
                  />
                  <label htmlFor="floatingInputCustom">{t('debit')}</label>
                  {errors.debit && (
                    <Form.Control.Feedback type="invalid">
                      {errors.debit.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('note')}
                type="text"
                placeholder={t('note')}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">{t('note')}</label>
            </Form.Floating>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('save')}
        </Button>
      </Form>
    </>
  );
};

export default AddSettelement;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useEffect, useState, useMemo, ChangeEvent, useRef } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowRotateBack } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import customerEntityService from 'service/charterer';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';
import FeatherIcon from 'feather-icons-react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import legalEntitySettingsService from 'service/legalEntitySettings';
import Button from 'components/base/Button';
import ReturnHistorySettlement from './ReturnHistorySettlement';
import documentStatusService from 'service/documentStatus';
import CompareReturnTable from './CompareReturnTable';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'dt_kt',
    url: '/dtkt/provider'
  },
  {
    label: 'history_offsetting_buyyer',
    active: true
  }
];

export type SettelementData = {
  cashier: string;
  currency: string;
  input_balance: string;
  amounts: string;
  output_balance: string;
  status: string;
  transaction_type: string;
  can_update: boolean;
  bg_color: string;
  text_color: string;
  movedToTop: string;
  isArrowRight: boolean;
  guid: string;
};

const HistoryPurchaseWithCustomer = () => {
  const { t, i18n } = useTranslation();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [openReturn, setOpenReturn] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [selectedStatusId, setSelectedStatusId] = useState<any>('');
  const [statusOption, setStatusOption] = useState<any>([]);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selecetedTargetId, setSelectedTargetId] = useState('');
  const [openSelected, setOpenSelected] = useState(false);

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };

  //GET Currency Entity to options
  useQuery(['GET_DOCUMENT_STATUS', i18n?.language], async () => {
    await documentStatusService
      .getList({
        offset: 0,
        limit: 100,
        status: true,
        show_dc_settlement: true
      })
      .then((res: any) => {
        const options = res?.document_statuses?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setStatusOption(options);
      });
  });

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000 
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_HISTORY_SETTLEMENT', loading],
    () => {
      const res = customerEntityService
        .getListHistorySettlement({
          offset: 0,
          limit: 1000,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          law_subject_type: 'INDIVIDUAL',
          status_id: selectedStatusId
        })
        .then((res: any) => {
          if (res) {
            setLoading(false);
          }
          return res?.debit_credit_settlements;
        });
      return res;
    },
    {
      enabled: !!dateFrom || !!adjustedDateTo || !!selectedStatusId || !!loading
    }
  );

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';
    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    const resultData =
      data?.map((items: any) => {
        return {
          cashier:
            items?.employee?.first_name + ' ' + items?.employee?.last_name,
          input_balance: items?.balance_in
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_in)
                .replace(/,/g, ' ')
            : 0,
          output_balance: items?.balance_out
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.balance_out)
                .replace(/,/g, ' ')
            : 0,
          amount: items?.amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.amount)
                .replace(/,/g, ' ')
            : 0,
          date_create: formatDateToTimeZone(items?.date_create, timeZone),
          status: items?.status?.name?.[i18n?.language],
          can_update: items?.can_update === true ? false : true,
          currency: items?.currency?.name?.[i18n?.language],
          bg_color: items?.status?.background_color,
          text_color: items?.status?.text_color,
          transaction_type: items?.transaction_type,
          guid: items?.id
        };
      }) ?? [];

    setSettlementData(resultData);
  }, [data, t, i18n?.language, dataSettings]);

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action-1',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            className="p-2"
            onClick={() => handleMoveToTop(rowData.row.original?.guid)}
          >
            <FeatherIcon icon="arrow-up" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'cashier',
      header: t('cashier'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'input_balance',
      header: t('input_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'amount',
      header: t('amounts'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'output_balance',
      header: t('output_balance'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'transaction_type',
      header: t('transaction_type'),
      cell: rowData => {
        /* prettier-ignore */
        return (
          <div>
            {rowData?.row?.original?.transaction_type === 'DEBIT'
              ? t('DEBIT')
              : rowData?.row?.original?.transaction_type === 'CREDIT'
                ? t('CREDIT')
                : rowData?.row?.original?.transaction_type === 'RETURN'
                  ? t('RETURN')
                  : t('RECALCULATION')}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_create',
      header: t('date_create'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status, bg_color, text_color } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          новый: { bg: '#1d4289', text: '#ffffff' },
          'Завершен с расхождением': { bg: '#fef3c7', text: '#92400e' },
          Yangi: { bg: '#fef3c7', text: '#92400e' },
          'Qisman tugallangan': { bg: '#fef3c7', text: '#92400e' }
        };
        /* prettier-ignore */
        const backgroundColor =
          bg_color || defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor =
          text_color || defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ position: 'fixed' }}>{t('return')}</Tooltip>
            }
          >
            <div>
              <Button
                variant="hover"
                className="p-2"
                disabled={rowData?.row?.original?.can_update}
                onClick={() => {
                  setOpenReturn(true);
                  setSelectedItems(rowData?.row?.original);
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateBack} className="fs-7" />
              </Button>
            </div>
          </OverlayTrigger>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: settlementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  const handleMoveToTop = (id: any) => {
    setOpenSelected(true);
    setSelectedTargetId(id);
  };

  useEffect(() => {
    if (dateFrom || adjustedDateTo || selectedStatusId) {
      refetch();
    }
  }, [dateFrom, adjustedDateTo, selectedStatusId]);

  useEffect(() => {
    // Set initial dateFrom to the beginning of the current month
    const today = new Date();
    const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateFrom(beginningOfMonth.getTime());

    // Set initial dateTo to today's date
    setDateTo(today.getTime());
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div ref={tableRef}>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{t('history_offsetting_buyyer')}</h2>
      </div>
      <div className="d-flex gap-2 mt-3">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder={t('search')}
              onChange={handleSearchInputChange}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              {t('search')}
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <Form.Group>
          <div className="react-select-container position-relative">
            <Form.Floating>
              <Form.Select
                onChange={(e: any) => {
                  const selectedOption = statusOption.find(
                    (option: any) => option.value === e.target.value
                  );
                  if (selectedOption) {
                    setSelectedStatusId(selectedOption?.value);
                  }
                }}
              >
                <option className="d-none" value=""></option>
                {statusOption?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">{t('status')}</label>
            </Form.Floating>
          </div>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder={t('date_from')}
            />
            <label htmlFor="floatingInputCustom">{t('date_from')}</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: getFlatpickrLocale(),
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder={t('date_to')}
            />
            <label htmlFor="floatingInputCustom">{t('date_to')}</label>
          </Form.Floating>
        </Form.Group>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Modal
        show={openSelected}
        onHide={() => {
          setOpenSelected(false);
          setSelectedTargetId('');
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('history_offsetting_buyyer')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompareReturnTable
            selecetedTargetId={selecetedTargetId}
            dateFrom={dateFrom}
            adjustedDateTo={adjustedDateTo}
            openSelected={openSelected}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={openReturn}
        onHide={() => {
          setOpenReturn(false);
          setSelectedItems({});
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="border border-300"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('return')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReturnHistorySettlement
            selectedItems={selectedItems}
            setOpenReturn={setOpenReturn}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HistoryPurchaseWithCustomer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntityCurrencyRateService = {
  getList: (params: any) =>
    request.get('/legal-entity-currency-rate', { params }),
  getListConversion: (params: any) =>
    request.get('/legal-entity-currency-conversion-rate/last', { params }),
  getListConversionRate: (params: any) =>
    request.get('/legal-entity-currency-conversion-rate', { params }),
  getRateById: (id: any) => request.get(`/legal-entity-currency-rate/${id}`),
  getCinversionRateById: (id: any) =>
    request.get(`/legal-entity-currency-conversion-rate/${id}`),
  createRate: (data: any) => request.post('/legal-entity-currency-rate', data),
  createConversionRate: (data: any) =>
    request.post('/legal-entity-currency-conversion-rate', data),
  deleteRate: (id: any) => request.delete(`/legal-entity-currency-rate/${id}`),
  deleteConversionRate: (id: any) =>
    request.delete(`/legal-entity-currency-conversion-rate/${id}`),
  updateRate: (id: any, data: any) =>
    request.put(`/legal-entity-currency-rate/${id}`, data),
  updateConversionRate: (id: any, data: any) =>
    request.put(`/legal-entity-currency-conversion-rate/${id}`, data)
};

export default legalEntityCurrencyRateService;

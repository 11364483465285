/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import usePasswordToggle from 'utils/usePasswordToggle';
import languageService from 'service/languages';
import { registerActions } from 'store/register/register.slice';
import Button from 'components/base/Button';

const genderOption = [
  { value: 'MALE', label: 'Мужской ' },
  { value: 'FEMALE', label: 'Женский ' }
];

const RegisterForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({});

  const inputRef = useRef<HTMLInputElement | any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [ResetPasswordInputType, ResetToggleIcon] = usePasswordToggle();

  const [langOption, setLangOption] = useState([]);

  //GET Lang to options
  useQuery(['GET_LANG'], async () => {
    await languageService
      .getListLangRegister({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.languages?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLangOption(options);
      });
  });

  const onSubmit = (data: any) => {
    console.log('data', data);
    const states = {
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.password,
      repeat_password: data.repeat_password,
      languages: data.languages,
      gender: data?.gender
    };
    dispatch(registerActions.setRegisterData(states));
    navigate('/register-next-step');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center mb-7">
        <h3 className="text-1000">{t('registration')}</h3>
        <p className="text-700">{t('to_start_register')}</p>
      </div>

      <Controller
        name="first_name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container">
            <Form.Floating className="mb-3">
              <Form.Control
                {...field}
                ref={inputRef}
                type="text"
                placeholder={t('name')}
                onFocus={e => e.target.select()}
                isInvalid={!!errors.first_name}
              />
              <label htmlFor="firstName">{t('name')}</label>
              {errors.first_name && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </div>
        )}
      />

      <Controller
        name="last_name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container">
            <Form.Floating className="mb-3">
              <Form.Control
                {...field}
                type="text"
                placeholder={t('second_name')}
                onFocus={e => e.target.select()}
                isInvalid={!!errors.last_name}
              />
              <label htmlFor="firstName">{t('second_name')}</label>
              {errors.last_name && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          </div>
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container position-relative">
            <Form.Floating className="mb-3 text-start">
              <Form.Control
                {...field}
                type={PasswordInputType}
                placeholder={t('password')}
                isInvalid={!!errors.password}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="oldPassword">{t('password')}</label>
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>

            <span
              style={{
                position: 'absolute',
                top: '12px',
                right: !errors.password ? '1rem' : '2rem',
                cursor: 'pointer'
              }}
            >
              {ToggleIcon}
            </span>
          </div>
        )}
      />

      <Controller
        name="repeat_password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form-icon-container position-relative">
            <Form.Floating className="mb-3 text-start">
              <Form.Control
                {...field}
                type={ResetPasswordInputType}
                placeholder={t('repeat_password')}
                isInvalid={!!errors.repeat_password}
                onFocus={e => e.target.select()}
              />
              <label htmlFor="oldPassword">{t('repeat_password')}</label>
              {errors.repeat_password && (
                <Form.Control.Feedback type="invalid">
                  {t('required_field')}
                </Form.Control.Feedback>
              )}
            </Form.Floating>

            <span
              style={{
                position: 'absolute',
                top: '12px',
                right: !errors.repeat_password ? '1rem' : '2rem',
                cursor: 'pointer'
              }}
            >
              {ResetToggleIcon}
            </span>
          </div>
        )}
      />

      <div className="react-select-container mb-3">
        <Controller
          name="languages"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.languages}>
                <option className="d-none" value=""></option>
                {langOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">{t('language')}</label>
              {errors?.languages && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  {t('required_field')}
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <div className="react-select-container mb-3">
        <Controller
          name="gender"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Form.Floating>
              <Form.Select {...field} isInvalid={!!errors.gender}>
                <option className="d-none" value=""></option>
                {genderOption?.map((option: any) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="option"
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>

              <label htmlFor="floatingInputCustom">{t('gender')}</label>
              {errors?.gender && (
                <span
                  style={{
                    marginTop: '2px',
                    color: 'red',
                    fontSize: '12px'
                  }}
                >
                  {t('required_field')}
                </span>
              )}
            </Form.Floating>
          )}
        />
      </div>

      <Button
        style={{ height: '48px', marginTop: 'auto' }}
        className="w-100 mb-3"
        variant="primary"
        type="submit"
        //   loading={loading}
      >
        {t('registration')}
      </Button>
    </Form>
  );
};

export default RegisterForm;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilCube,
  UilChartPie,
  UilMoneyWithdrawal
} from '@iconscout/react-unicons';
import {
  faEdit,
  faChartSimple,
  faCalculator,
  faCogs,
  faMoneyBillTransfer,
  faCalendarXmark,
  faArrowRightArrowLeft
} from '@fortawesome/free-solid-svg-icons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'home_page',
        icon: 'home',
        path: '/',
        pathName: 'dashboard',
        active: true
      }
    ]
  },
  {
    label: 'legal',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'legal_entities',
        icon: 'briefcase',
        path: '/legal',
        pathName: 'legal-entites',
        active: true
      }
    ]
  },
  {
    label: 'clients',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'control',
        iconSet: 'font-awesome',
        icon: faCogs,
        active: true,
        flat: true,
        pages: [
          {
            name: 'users',
            path: '/clients',
            pathName: 'clients',
            active: true
          },
          {
            name: 'roles',
            path: '/roles',
            pathName: 'roles',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'directories',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'directories',
        icon: 'book-open',
        active: true,
        flat: true,
        pages: [
          {
            name: 'warehouse',
            path: '/directories/stock',
            pathName: 'stock-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'currency',
            path: '/directories/currency',
            pathName: 'currency-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'exchange_rates',
            path: '/directories/rate',
            pathName: 'rate-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },

          {
            name: 'payment_type',
            path: '/directories/payment',
            pathName: 'payment-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'vat',
            path: '/directories/nds',
            pathName: 'nds-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'wallet',
            path: '/directories/wallet',
            pathName: 'wallet-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          // {
          //   name: 'Счета',
          //   path: '/directories/accounts',
          //   pathName: 'accounts-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'financial_account',
            path: '/directories/articles',
            pathName: 'articles-directories',
            active: true
          },

          {
            name: 'cash',
            path: '/directories/cash',
            pathName: 'cash-directories',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'currency-conversion',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'currency_conversion',
        iconSet: 'font-awesome',
        icon: faMoneyBillTransfer,
        active: true,
        flat: true,
        pages: [
          {
            name: 'currency_exchange_rate',
            path: '/currency-conversion/exchange-rate',
            pathName: 'exchange-rate-currency-conversion',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'exchange',
            path: '/currency-conversion/conversion',
            pathName: 'conversion-currency-conversion',
            topNavIcon: 'shopping-cart',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'cash_transaction',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'cash_transaction',
        iconSet: 'font-awesome',
        icon: faArrowRightArrowLeft,
        path: '/cash-transaction',
        pathName: 'cash-transaction',
        active: true
      }
    ]
  },

  {
    label: 'products',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'products',
        icon: 'database',
        active: true,
        flat: true,
        pages: [
          {
            name: 'my_products',
            path: '/my-products',
            pathName: 'myProducts',
            active: true
          },

          {
            name: 'scale_weight_product',
            path: '/scales',
            pathName: 'scales',
            active: true
          },
          {
            name: 'group_product',
            path: '/products',
            pathName: 'products',
            active: true
          },
          {
            name: 'marriage_goods',
            path: '/marrige-goods',
            pathName: 'marrige-goods',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'purchase',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'purchase',
        icon: 'shopping-cart',
        path: '/purchase/create',
        pathName: 'create-purchase',
        active: true
      }
    ]
  },

  {
    label: 'returning',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'returns',
        icon: 'download-cloud',
        active: true,
        flat: true,
        pages: [
          {
            name: 'outgoing',
            path: '/returning/outgoing',
            pathName: 'outgoing-returning',
            active: true
          },
          {
            name: 'incoming',
            path: '/returning/coming',
            pathName: 'coming-returning',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'bonuses',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'bonuses',
        icon: 'award',
        path: '/bonuses',
        pathName: 'bonuses',
        active: true
      }
    ]
  },

  {
    label: 'applications',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'applications',
        icon: 'file-text',
        active: true,
        flat: true,
        pages: [
          {
            name: 'outgoing',
            path: '/applications/outgoing',
            pathName: 'outgoing-applications',
            active: true
          },
          {
            name: 'incoming',
            path: '/applications/coming',
            pathName: 'coming-applications',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'movements',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'movements',
        icon: 'move',
        active: true,
        flat: true,
        pages: [
          {
            name: 'outgoing',
            path: '/movements/outgoing',
            pathName: 'outgoing-movements',
            active: true
          },
          {
            name: 'incoming',
            path: '/movements/coming',
            pathName: 'coming-movements',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'dtkt',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'dt_kt',
        icon: 'user-check',
        active: true,
        flat: true,
        pages: [
          {
            name: 'supplier',
            path: '/dtkt/provider',
            pathName: 'provider-dtkt',
            active: true
          },

          {
            name: 'settlement_supplier',
            path: '/dtkt/suppliers',
            pathName: 'suppliers-dtkt',
            active: true
          },

          // {
          //   name: 'supplier_repayment_terms',
          //   path: '/dtkt/suppliers-maturity-date',
          //   pathName: 'suppliers-maturity-date-dtkt',
          //   active: true
          // },
          {
            name: 'customer',
            path: '/dtkt/customer',
            pathName: 'customer-dtkt',
            active: true
          },
          {
            name: 'settlement_customer',
            path: '/dtkt/buyyers',
            pathName: 'buyyers-dtkt',
            active: true
          },
          // {
          //   name: 'customer_repayment_terms',
          //   path: '/dtkt/customer-maturity-date',
          //   pathName: 'customer-maturity-date-dtkt',
          //   active: true
          // },
          {
            name: 'buyer',
            path: '/dtkt/purchaser',
            pathName: 'purchaser-dtkt',
            active: true
          },
          {
            name: 'offsetting_buyyer',
            path: '/dtkt/purchaser-with-customer',
            pathName: 'customer-with-purchaser-dtkt',
            active: true
          },
          // {
          //   name: 'buyer_repayment_terms',
          //   path: '/dtkt/purchaser-repayment-term',
          //   pathName: 'term-repayment-purchaser-dtkt',
          //   active: true
          // },
          {
            name: 'history_offsetting_buyyer',
            path: '/dtkt/purchaser-with-customer-history',
            pathName: 'history-customer-with-purchaser-dtkt',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'inventory',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'inventory',
        icon: 'codesandbox',
        active: true,
        flat: true,
        pages: [
          {
            name: 'create_inventory',
            path: '/inventory/create-inventory',
            pathName: 'create-inventory',
            active: true
          },

          {
            name: 'inventory_information',
            path: '/inventory/info',
            pathName: 'info-inventory',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'write-off',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'write-off',
        iconSet: 'font-awesome',
        icon: faCalendarXmark,
        active: true,
        flat: true,
        pages: [
          {
            name: 'according_inventory_results',
            path: '/write-off/write-off-inventory',
            pathName: 'write-off-inventory',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'expenses',
    active: true,
    icon: UilMoneyWithdrawal,
    labelDisabled: true,
    pages: [
      {
        name: 'cost',
        icon: 'package',
        path: '/expenses',
        pathName: 'expenses',
        active: true
      }
    ]
  },
  {
    label: 'reports',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'reports',
        iconSet: 'font-awesome',
        icon: faEdit,
        active: true,
        flat: true,
        pages: [
          {
            name: 'purchase',
            active: true,
            pages: [
              {
                name: 'purchase_invoice',
                path: '/reports/purchase/invoice',
                pathName: 'invoice-purchase-reports',
                active: true
              },
              {
                name: 'purchase_products',
                path: '/reports/purchase/goods',
                pathName: 'goods-purchase-reports',
                active: true
              }
            ]
          },
          {
            name: 'sale',
            active: true,
            pages: [
              // {
              //   name: 'По платежам',
              //   path: '/reports/sale/payments',
              //   pathName: 'payments-sale-reports',
              //   active: true
              // },
              {
                name: 'sales_product',
                path: '/reports/sale/product',
                pathName: 'product-sale-reports',
                active: true
              }
            ]
          },
          {
            name: 'remainder',
            path: '/reports/remainder',
            pathName: 'remainder-reports',
            active: true
          },
          {
            name: 'gross_income',
            path: '/reports/income',
            pathName: 'income-reports',
            active: true
          },
          {
            name: 'cash',
            path: '/reports/cash-info',
            pathName: 'cash-info-reports',
            active: true
          }
          // {
          //   name: 'Срок годности',
          //   pathName: 'term-reports'
          // },
          // {
          //   name: 'Возврат',
          //   pathName: 'return-reports'
          // }
        ]
      }
    ]
  },
  {
    label: 'analytics',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'analytics',
        iconSet: 'font-awesome',
        icon: faChartSimple,
        active: true,
        flat: true,
        pages: [
          {
            name: 'revaluation_products',
            active: true,
            pages: [
              {
                name: 'revaluation_invoice',
                path: '/analytics/revaluation/revaluation-invoice',
                pathName: 'invoice-revaluation-analytics',
                active: true
              },
              {
                name: 'revaluation_goods',
                path: '/analytics/revaluation/revaluation-product',
                pathName: 'product-revaluation-analytics',
                active: true
              }
            ]
          },
          {
            name: 'abc_analysis',
            path: '/analytics/abc',
            pathName: 'analysis-analytics',
            active: true
          },
          {
            name: 'auto_order',
            path: '/analytics/autoorder',
            pathName: 'autoorder-analytics',
            active: true
          },
          {
            name: 'frozen_remedy',
            path: '/analytics/frozen-funds',
            pathName: 'frozen-funds-analytics',
            active: true
          },
          {
            name: 'lost_profit',
            path: '/analytics/profit',
            pathName: 'profit-analytics',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'finance',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'finance',
        iconSet: 'font-awesome',
        icon: faCalculator,
        active: true,
        flat: true,
        pages: [
          {
            name: 'dds',
            path: '/finance/cashflow',
            pathName: 'cashflow-finance',
            active: true
          },
          {
            name: 'p_and_l',
            path: '/finance/profit-and-lost',
            pathName: 'profit-and-lost-finance',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'settings',
    active: true,
    icon: UilMoneyWithdrawal,
    labelDisabled: true,
    pages: [
      {
        name: 'settings',
        icon: 'settings',
        path: '/settings',
        pathName: 'settings',
        active: true
      }
    ]
  }
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useMemo, useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import DocPageHeader from 'components/docs/DocPageHeader';
import Button from 'components/base/Button';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';
import { showAlert } from 'store/alert/alert.thunk';
import currencyService from 'service/currency';

import './styles.scss';

interface CreateRateProps {
  setOpenRate: (show: boolean) => void;
  refetch?: any;
  selectedRateId?: any;
  setSelectedRateId?: any;
}

const CreateRate = ({
  setOpenRate,
  selectedRateId,
  refetch,
  setSelectedRateId
}: CreateRateProps) => {
  const { t, i18n } = useTranslation();

  const schema = yup.object().shape({
    base: yup.string(),
    main_currency: yup.string().required(t('required_field')),
    convertible: yup.string().required(t('required_field')),
    units: yup.string().required(t('required_field'))
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const dispatch: Dispatch<any> = useDispatch();
  const hasFetchedData = useRef(false);
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [mainCurrencyOptions, setMainCurrencyOptions] = useState<any[]>([]);
  const [unitsOptions, setUnitsOptions] = useState<any[]>([]);
  const [originalUnitsOptions, setOriginalUnitsOptions] = useState<any>([]);

  const [selectedMainCurrency, setSelectedMainCurrency] = useState<any>(null);

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setMainCurrencyOptions(options);
        setUnitsOptions(options);
        setOriginalUnitsOptions(options);
      });
  });

  //Get by id
  const fetchData = useMemo(() => {
    return () => {
      if (selectedRateId === '') return setLoading(false);

      legalEntityCurrencyRateService
        .getRateById(selectedRateId)
        .then((res: any) => {
          const computed = {
            main_currency: res?.from_currency?.id,

            base: '1',
            units: res?.to_currency?.id,

            convertible: res?.to_currency_rate
          };
          reset(computed);
        })
        .finally(() => setLoading(false));
    };
  }, [selectedRateId]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedRateId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      from_currency_id: data?.main_currency,
      from_currency_rate: 1,
      to_currency_id: data?.units,
      to_currency_rate: +data?.convertible
    };

    legalEntityCurrencyRateService
      .createRate(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('exchange_rates')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setOpenRate(false);
        setLoading(false);
        setSelectedRateId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      from_currency_id: data?.main_currency,
      from_currency_rate: 1,
      to_currency_id: data?.units,
      to_currency_rate: +data?.convertible,
      id: selectedRateId
    };
    legalEntityCurrencyRateService
      .updateRate(selectedRateId, updateData)
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenRate(false);
        setSelectedRateId('');
      });
  };

  useEffect(() => {
    if (selectedMainCurrency && inputRef.current) {
      inputRef.current.focus();
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current, selectedMainCurrency]);

  useEffect(() => {
    if (selectedRateId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedRateId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="create-rate"
      >
        <div className="rate-box">
          <Controller
            name="base"
            control={control}
            render={({ field }) => (
              <Form.Floating className="w-100">
                <Form.Control
                  defaultValue="1"
                  disabled
                  {...field}
                  type="text"
                  placeholder="Base currency"
                  onFocus={e => e.target.select()}
                />
                <label htmlFor="floatingInputCustom">
                  {t('unit_base_currency')}
                </label>
              </Form.Floating>
            )}
          />

          <Form.Group className="w-100">
            <div className="react-select-container">
              <Controller
                name="main_currency"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      ref={inputRef}
                      onChange={(e: any) => {
                        const selectedOption = mainCurrencyOptions.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedOption) {
                          setSelectedMainCurrency(selectedOption);

                          const updatedUnitsOptions =
                            originalUnitsOptions.filter(
                              (option: any) =>
                                option.value !== selectedOption?.value
                            );
                          console.log(
                            'updatedUnitsOptions',
                            updatedUnitsOptions
                          );
                          if (updatedUnitsOptions.length === 1) {
                            setUnitsOptions(updatedUnitsOptions);
                            setValue('units', updatedUnitsOptions[0].value); // Automatically select the first available option if length is 2
                          } else {
                            setUnitsOptions(updatedUnitsOptions);
                            setValue('units', ''); // Clear the units select if length is not 2
                          }
                        }

                        field.onChange(e);
                      }}
                      isInvalid={!!errors.main_currency}
                    >
                      <option className="d-none" value=""></option>
                      {mainCurrencyOptions?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      {t('main_currency')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.main_currency?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.main_currency?.message}
                </span>
              )}
            </div>
          </Form.Group>

          <Form.Group>
            <DocPageHeader
              className="d-flex justify-content-center align-items-center"
              title="="
            />
          </Form.Group>

          <Controller
            name="convertible"
            control={control}
            render={({ field }) => (
              <Form.Floating className="w-100">
                <Form.Control
                  {...field}
                  type="text"
                  ref={selectedMainCurrency && inputRef}
                  placeholder="Base convertible"
                  isInvalid={!!errors.convertible}
                  onFocus={e => e.target.select()}
                  autoComplete="off"
                />
                <label htmlFor="floatingInputCustom">
                  {t('convertible_currency')}
                </label>
                {errors.convertible && (
                  <Form.Control.Feedback type="invalid">
                    {errors.convertible.message}
                  </Form.Control.Feedback>
                )}
              </Form.Floating>
            )}
          />

          <Form.Group className="w-100">
            <div className="react-select-container">
              <Controller
                name="units"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = unitsOptions.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedMainCurrency) {
                          setUnitsOptions(originalUnitsOptions);
                          setSelectedMainCurrency(null);
                        } else {
                          setUnitsOptions(
                            unitsOptions.filter(
                              (option: any) =>
                                option.value !== selectedOption?.value
                            )
                          );
                        }
                        field.onChange(e);
                      }}
                      isInvalid={!!errors.units}
                    >
                      <option className="d-none" value=""></option>
                      {unitsOptions?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      {t('convertible_currency_unit')}
                    </label>
                  </Form.Floating>
                )}
              />
              {errors?.units?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.units?.message}
                </span>
              )}
            </div>
          </Form.Group>
        </div>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          {t('save')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateRate;

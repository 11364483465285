/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import legalEntitySettingsService from 'service/legalEntitySettings';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CreateCurrencyExchangeRate from './CreateCurrencyExchangeRate';

export const currencyExchangeRateBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'currency_conversion',
    url: '/currency-conversion/exchange-rate'
  },
  {
    label: 'currency_exchange_rate',
    active: true
  }
];

export type CurrencyExchangeRateType = {
  base: string;
  main_currency: string;
  convertible: string;
  units: string;
  create_date: string;
  guid: string;
};

const CurrencyExchangeRate = () => {
  const { t, i18n } = useTranslation();

  const [openConversion, setOpenConversion] = useState(false);

  const { data: dataSettings } = useQuery(['GET_LEGAL_ENTITY_SETTINGS'], () => {
    const res = legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => res?.legal_entity_settings);
    return res;
  });

  //Get Legal entity
  const { data, refetch } = useQuery(['GET_CONVERSION_RATE'], () => {
    const res = legalEntityCurrencyRateService
      .getListConversionRate({
        limit: 1000,
        offset: 0
      })
      .then((res: any) => res?.legal_entity_currency_conversion_rates);
    return res;
  });

  /* prettier-ignore */
  const formatDateToTimeZone = (date: number, timeZone: string) => {
    try {
      // Format the date according to the specified time zone
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone
      }).format(new Date(date));
    } catch (error) {
      console.error('Error formatting date with time zone:', timeZone, error);
      return ''; // Return empty string if the time zone is invalid
    }
  };

  const currencyExchangeRateData: CurrencyExchangeRateType[] = useMemo(() => {
    let timeZone = dataSettings?.[0]?.timeZone?.name?.ru ?? 'UTC';

    timeZone = timeZone.replace(/\s*\(.*?\)/, '').trim();

    return (
      data?.map((items: any) => {
        return {
          base: items?.income_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.income_amount)
                .replace(/,/g, ' ')
            : '0',
          main_currency: items?.income_currency?.name?.[i18n?.language],
          convertible: items?.outcome_amount
            ? new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
                useGrouping: true
              })
                .format(items?.outcome_amount)
                .replace(/,/g, ' ')
            : '0',
          units: items?.outcome_currency?.name?.[i18n?.language],
          /* prettier-ignore */
          create_date: formatDateToTimeZone(items?.date_create, timeZone),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, dataSettings, t, i18n?.language]);

  const currencyExchangeRateTableColumns: ColumnDef<CurrencyExchangeRateType>[] =
    [
      {
        accessorKey: 'base',
        header: t('unit_base_currency'),
        meta: {
          cellProps: { className: 'text-900' }
        }
      },

      {
        accessorKey: 'main_currency',
        header: t('main_currency'),
        meta: {
          cellProps: { className: 'text-900' }
        }
      },

      {
        accessorKey: 'convertible',
        header: t('convertible_currency'),
        meta: {
          cellProps: { className: 'text-900' }
        }
      },

      {
        accessorKey: 'units',
        header: t('convertible_currency_unit'),
        meta: {
          cellProps: { className: 'text-900' }
        }
      },

      {
        accessorKey: 'create_date',
        header: t('date_create'),
        meta: {
          cellProps: { className: 'text-900' }
        }
      }
    ];

  const table = useAdvanceTable({
    data: currencyExchangeRateData,
    columns: currencyExchangeRateTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={currencyExchangeRateBreadcrumbItems} />

      <div className="mb-9">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h2>{t('currency_exchange_rate')}</h2>

          <Button
            style={{ height: '48px' }}
            variant="primary"
            onClick={() => setOpenConversion(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('add')}
          </Button>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Modal
            show={openConversion}
            onHide={() => {
              setOpenConversion(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('add')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateCurrencyExchangeRate
                setOpenConversion={setOpenConversion}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default CurrencyExchangeRate;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo, useState } from 'react';
import { Col, Row, Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CurrencyTable from './CurrencyTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { currencyBreadcrumbItems } from 'data/currency';
import { CurrencyType } from 'data/currency';
import CreateCurrency from './CreateCurrency';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

const Currency = () => {
  const { t, i18n } = useTranslation();

  const [openCurrency, setOpenCurrency] = useState(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState('');

  //Get Currency
  const { data, refetch } = useQuery(['GET_CURRENCY'], () => {
    const res = legalEntityCurrencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entity_currencies);
    return res;
  });

  const currencyData: CurrencyType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.currency?.name?.[i18n?.language],
          main_currency: items?.is_main === true ? t('yes') : t('no'),
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const currencyTableColumns: ColumnDef<CurrencyType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              setOpenCurrency(true);
              setSelectedCurrencyId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('currency_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'main_currency',
      header: t('main_currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: currencyData,
    columns: currencyTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={currencyBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('currency')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <CurrencyTable />
          </div>

          <Offcanvas
            show={openCurrency}
            onHide={() => {
              setOpenCurrency(false);
              setSelectedCurrencyId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateCurrency
                setOpenCurrency={setOpenCurrency}
                refetch={refetch}
                selectedCurrencyId={selectedCurrencyId}
                setSelectedCurrencyId={setSelectedCurrencyId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Currency;

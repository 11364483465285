/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import LegalEntityTable from './LegalEntityTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import {
  LegalEntityType,
  legalEntityBreadcrumbItems
} from 'data/legalentities';
import CreateLegalEntity from './CreateLegalEntity';
import legalEntityService from 'service/legalEntity';
import Lightbox from 'components/base/LightBox';
import useLightbox from 'hooks/useLightbox';

const LegalEntity = () => {
  const { t, i18n } = useTranslation();

  const [openLegalCreate, setOpenLegalCreate] = useState(false);
  const [selectedLegalId, setSelectedLegalId] = useState('');

  //Get Legal entity
  const { data, refetch } = useQuery(['GET_LEGAL_ENTITY'], async () => {
    const res = await legalEntityService
      .getList({ offset: 0, limit: 10 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  const legalData: LegalEntityType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          physical_name: items?.name,
          address: items?.address,
          phone_number: items?.phone_number,
          kinds: items?.activity_kind?.name?.[i18n?.language],
          country: items?.country?.name?.[i18n?.language],
          def_currency: items?.currency?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          access: items?.legal_entity_access?.name?.[i18n?.language],
          status: items?.legal_entity_status?.name?.[i18n?.language],
          type_physical_name: items?.legal_entity_type,
          logo_url: items?.logo_url
            ? `${process.env.REACT_APP_CDN_URL}${items.logo_url}`
            : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const legalEntityTableColumns: ColumnDef<LegalEntityType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              setOpenLegalCreate(true);
              setSelectedLegalId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'physical_name',
      header: t('name_legal_entity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'type_physical_name',
      header: t('legal_entity_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'kinds',
      header: t('activity_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'def_currency',
      header: t('default_currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: {
          className: 'text-center',
          style: { whiteSpace: 'nowrap' }
        }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: t('address'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },

    {
      accessorKey: 'phone_number',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      cell: rowData => {
        const { status } = rowData?.row?.original || {};
        /* prettier-ignore */
        const defaultColors: any = {
          [t('active')]: { bg: '#BBFBD0', text: '#119C2B' },
          [t('blocked')]: { bg: '#FDDFDD', text: '#F2271C' }
        };
        /* prettier-ignore */
        const backgroundColor =
         defaultColors[status]?.bg || '#ffffff'; // Default to white if not specified
        const textColor = defaultColors[status]?.text || '#000000';

        return (
          <div
            style={{
              background: backgroundColor,
              padding: '8px 10px',
              textAlign: 'center',
              borderRadius: '8px',
              color: textColor,
              fontWeight: '600'
            }}
          >
            {status}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'photo',
      header: t('photo'),
      cell: rowData => {
        const [attachments] = useState([rowData?.row?.original.logo_url]);
        const { lightboxProps, openLightbox } = useLightbox(attachments);

        return (
          <>
            {rowData?.row?.original.logo_url && (
              <div>
                <Lightbox {...lightboxProps} />

                {attachments.map((img, index) => (
                  <img
                    key={img}
                    style={{
                      width: '35px',
                      height: '35px',
                      border: '1px solid #8200BF',
                      borderRadius: '100%',
                      cursor: 'pointer'
                    }}
                    src={img}
                    alt=""
                    onClick={() => openLightbox(index + 1)}
                  />
                ))}
              </div>
            )}
          </>
        );
      },

      meta: {
        cellProps: { className: 'text-900 text-center' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: legalData,
    columns: legalEntityTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={legalEntityBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('legal_entities')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button
                  variant="primary"
                  onClick={() => setOpenLegalCreate(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <LegalEntityTable />
          </div>

          <Offcanvas
            show={openLegalCreate}
            onHide={() => {
              setOpenLegalCreate(false);
              setSelectedLegalId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateLegalEntity
                setOpenLegalCreate={setOpenLegalCreate}
                selectedLegalId={selectedLegalId}
                refetch={refetch}
                setSelectedLegalId={setSelectedLegalId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default LegalEntity;
